import React, { useState, useEffect } from 'react';
import { db } from '../components/firebase'; // Ensure the correct path
import { doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom'; // For routing and navigation

const EditBooking = () => {
  const { bookingId } = useParams(); // Get the booking ID from the URL
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [booking, setBooking] = useState(null);
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBooking();
  }, [bookingId]);

  const fetchBooking = async () => {
    setLoading(true);
    setError('');
    try {
      const bookingDoc = await getDoc(doc(db, 'bookings', bookingId));
      if (bookingDoc.exists()) {
        const data = bookingDoc.data();
        setBooking(data);
        setCheckInDate(data.checkIn.toDate().toISOString().split('T')[0]);
        setCheckOutDate(data.checkOut.toDate().toISOString().split('T')[0]);
        setRooms(data.rooms);
      } else {
        setError('Booking not found.');
      }
    } catch (error) {
      console.error('Error fetching booking: ', error);
      setError('Failed to fetch booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleQuantityChange = (index, value) => {
    const updatedRooms = rooms.map((room, i) => (
      i === index ? { ...room, quantity: value } : room
    ));
    setRooms(updatedRooms);
  };

  const handleSubmit = async () => {
    if (!checkInDate || !checkOutDate || rooms.some(room => room.quantity < 1)) {
      setError('Please fill in all fields correctly.');
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {
      const checkInTimestamp = Timestamp.fromDate(new Date(checkInDate));
      const checkOutTimestamp = Timestamp.fromDate(new Date(checkOutDate));

      await updateDoc(doc(db, 'bookings', bookingId), {
        checkIn: checkInTimestamp,
        checkOut: checkOutTimestamp,
        rooms
      });

      setMessage('Booking updated successfully.');
      setTimeout(() => {
        navigate('/manage'); // Redirect to Manage Bookings after successful update
      }, 1500);
    } catch (error) {
      console.error("Error updating booking: ", error);
      setError('Failed to update booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ fontFamily: 'Times Roman', padding: '20px' }}>
      <h2 className="text-2xl font-bold mb-4">Edit Booking</h2>
      {loading && <p>Loading...</p>}
      {message && <p className="text-green-500">{message}</p>}
      {error && <p className="text-red-500">{error}</p>}
      {booking && (
        <>
          <div className="mb-4">
            <label className="block font-bold">Check-in Date:</label>
            <input
              type="date"
              value={checkInDate}
              onChange={(e) => setCheckInDate(e.target.value)}
              className="border p-2 rounded"
              disabled // Disable date input if you don't want to change the date
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold">Check-out Date:</label>
            <input
              type="date"
              value={checkOutDate}
              onChange={(e) => setCheckOutDate(e.target.value)}
              className="border p-2 rounded"
              disabled // Disable date input if you don't want to change the date
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold">Rooms:</label>
            {rooms.map((room, index) => (
              <div key={index} className="flex items-center mb-2">
                <span className="mr-2">{room.type}:</span>
                <input
                  type="number"
                  min="1"
                  value={room.quantity}
                  onChange={(e) => handleQuantityChange(index, Number(e.target.value))}
                  className="border p-2 rounded mr-2"
                />
              </div>
            ))}
          </div>
          <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded">Update Booking</button>
        </>
      )}
    </div>
  );
};

export default EditBooking;
