import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { db } from '../components/firebase'; // Ensure the correct path
import { collection, doc, query, where, getDocs, addDoc, updateDoc, Timestamp } from 'firebase/firestore';

const roomTypes = ['Standard Room', 'TwinBed Room', 'Sweet Room', 'SemiSweet Room']; // Define your room types

const AdminBooking = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [rooms, setRooms] = useState([{ type: roomTypes[0], quantity: 1 }]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleRoomChange = (index, field, value) => {
    const updatedRooms = rooms.map((room, i) => (
      i === index ? { ...room, [field]: value } : room
    ));
    setRooms(updatedRooms);
  };

  const handleSubmit = async () => {
    if (!checkInDate || !checkOutDate || rooms.some(room => !room.type || room.quantity < 1)) {
      setError('Please fill in all fields correctly.');
      return;
    }

    setLoading(true);
    setError('');
    setMessage('');

    try {
      const checkInTimestamp = Timestamp.fromDate(new Date(checkInDate));
      const checkOutTimestamp = Timestamp.fromDate(new Date(checkOutDate));

      const q = query(
        collection(db, 'bookings'),
        where('checkIn', '==', checkInTimestamp),
        where('checkOut', '==', checkOutTimestamp)
      );

      const querySnapshot = await getDocs(q);
      let bookingId = null;
      let existingRooms = [];

      if (!querySnapshot.empty) {
        const bookingDoc = querySnapshot.docs[0];
        bookingId = bookingDoc.id;
        existingRooms = bookingDoc.data().rooms;
      }

      if (bookingId) {
        // Update existing booking
        const updatedRooms = rooms.map(newRoom => {
          const existingRoom = existingRooms.find(room => room.type === newRoom.type);
          if (existingRoom) {
            return { ...existingRoom, quantity: existingRoom.quantity + newRoom.quantity };
          }
          return newRoom;
        });

        const mergedRooms = [
          ...updatedRooms,
          ...existingRooms.filter(existingRoom => !updatedRooms.find(newRoom => newRoom.type === existingRoom.type))
        ];

        await updateDoc(doc(db, 'bookings', bookingId), { rooms: mergedRooms });
        setMessage('Booking updated successfully.');
      } else {
        // Create new booking
        await addDoc(collection(db, 'bookings'), {
          checkIn: checkInTimestamp,
          checkOut: checkOutTimestamp,
          rooms
        });
        setMessage('Booking created successfully.');
      }

      // Redirect to the admin page and reload
      setCheckInDate('');
      setCheckOutDate('');
      setRooms([{ type: roomTypes[0], quantity: 1 }]);
      navigate('/manage'); // Change '/admin' to your actual admin page route
      window.location.reload(); // Optional: reload the page
    } catch (error) {
      console.error("Error recording booking: ", error);
      setError('Failed to record booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4 sm:p-8 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold mb-4 font-times">Admin Booking Recording</h2>
      {loading && <p className="font-times">Loading...</p>}
      {message && <p className="text-green-500 font-times">{message}</p>}
      {error && <p className="text-red-500 font-times">{error}</p>}
      <div className="mb-4">
        <label className="font-times">Check-in Date:</label>
        <input
          type="date"
          value={checkInDate}
          onChange={(e) => setCheckInDate(e.target.value)}
          className="border p-2 rounded font-times"
        />
      </div>
      <div className="mb-4">
        <label className="font-times">Check-out Date:</label>
        <input
          type="date"
          value={checkOutDate}
          onChange={(e) => setCheckOutDate(e.target.value)}
          className="border p-2 rounded font-times"
        />
      </div>
      <div className="mb-4">
        <label className="font-times">Rooms:</label>
        {rooms.map((room, index) => (
          <div key={index} className="flex items-center mb-2">
            <select
              value={room.type}
              onChange={(e) => handleRoomChange(index, 'type', e.target.value)}
              className="border p-2 rounded mr-2 font-times"
            >
              {roomTypes.map((type) => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
            <input
              type="number"
              min="1"
              value={room.quantity}
              onChange={(e) => handleRoomChange(index, 'quantity', Number(e.target.value))}
              className="border p-2 rounded mr-2 font-times"
            />
          </div>
        ))}
      </div>
      <button onClick={handleSubmit} className="bg-green-500 text-white p-2 rounded font-times">Submit Booking</button>
    </div>
  );
};

export default AdminBooking;
