import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import { db } from '../components/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';

const AddReservationDialog = ({ open, onClose, onAdd }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [roomType, setRoomType] = useState('');
  const [checkIn, setCheckIn] = useState('');
  const [checkOut, setCheckOut] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);

  const handleAddReservation = async (event) => {
    event.preventDefault(); // Prevents form submission refresh
    try {
      await addDoc(collection(db, 'reservations'), {
        name,
        email,
        phone,
        roomType,
        checkIn: Timestamp.fromDate(new Date(checkIn)),
        checkOut: Timestamp.fromDate(new Date(checkOut)),
        adults,
        children,
        timestamp: Timestamp.now(),
      });
      onAdd();  // Notify parent component of the new reservation
      handleCancel();  // Reset form and close dialog
    } catch (error) {
      console.error('Error adding reservation: ', error);
    }
  };

  const handleCancel = () => {
    // Reset form fields
    setName('');
    setEmail('');
    setPhone('');
    setRoomType('');
    setCheckIn('');
    setCheckOut('');
    setAdults(1);
    setChildren(0);
    onClose();  // Close dialog
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ color: '#1E90FF', fontWeight: 'bold' }}>Add Reservation</DialogTitle>
      <DialogContent>
        <form onSubmit={handleAddReservation} className="max-w-lg mx-auto p-4 md:p-8 bg-white rounded shadow-md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="name" className="block text-sm font-times font-medium text-custom-blue">Name</label>
                <input
                  autoFocus
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="email" className="block text-sm  font-times font-medium text-custom-blue">Email</label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="phone" className="block text-sm  font-times font-medium text-custom-blue">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="roomType" className="block text-sm  font-times font-medium text-custom-blue">Room Type</label>
                <select
                  id="roomType"
                  value={roomType}
                  onChange={(e) => setRoomType(e.target.value)}
                  className="w-full px-3 py-2  font-times border rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                >
                  <option value="">Select Room Type</option>
                  <option value="Standard Room">Standard Room</option>
                  <option value="TwinBed Room">TwinBed Room</option>
                  <option value="SemiSweet Room">SemiSweet Room</option>
                  <option value="Sweet Room">Sweet Room</option>
                </select>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="checkIn" className="block text-sm  font-times font-medium text-custom-blue">Check-in Date</label>
                <input
                  type="date"
                  id="checkIn"
                  value={checkIn}
                  onChange={(e) => setCheckIn(e.target.value)}
                  className="w-full px-3 py-2 border  font-times rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="checkOut" className="block text-sm  font-times font-medium text-custom-blue">Check-out Date</label>
                <input
                  type="date"
                  id="checkOut"
                  value={checkOut}
                  onChange={(e) => setCheckOut(e.target.value)}
                  className="w-full px-3 py-2 border  font-times rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="adults" className="block text-sm  font-times font-medium text-custom-blue">Adults</label>
                <input
                  type="number"
                  id="adults"
                  placeholder="Adults"
                  value={adults}
                  onChange={(e) => setAdults(Number(e.target.value))}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                  min="1"
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="input-field mb-4">
                <label htmlFor="children" className="block text-sm  font-times font-medium text-custom-blue">Children</label>
                <input
                  type="number"
                  id="children"
                  placeholder="Children"
                  value={children}
                  onChange={(e) => setChildren(Number(e.target.value))}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-custom-blue"
                  required
                  min="0"
                />
              </div>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleCancel} style={{ color: '#1E90FF' }}>
              Cancel
            </Button>
            <Button type="submit" style={{ backgroundColor: '#1E90FF', color: '#fff' }}>
              Add
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddReservationDialog;
