import React, { useState, useEffect } from 'react';
import { db } from '../components/firebase'; // Ensure the correct path
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Link } from 'react-router-dom'; // For routing to edit page

const ManageBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchBookings();
  }, []);

  const fetchBookings = async () => {
    setLoading(true);
    setError('');
    try {
      const querySnapshot = await getDocs(collection(db, 'bookings'));
      const bookingsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBookings(bookingsList);
    } catch (error) {
      console.error('Error fetching bookings: ', error);
      setError('Failed to fetch bookings. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this booking?')) {
      try {
        await deleteDoc(doc(db, 'bookings', id));
        setBookings(bookings.filter(booking => booking.id !== id));
        alert('Booking deleted successfully.');
      } catch (error) {
        console.error('Error deleting booking: ', error);
        alert('Failed to delete booking. Please try again.');
      }
    }
  };

  return (
    <div className="p-6 bg-gray-50  font-times min-h-screen">
      <h2 className="text-3xl font-bold font-times mb-6 text-custom-blue">Manage Bookings</h2>
      {loading && <p className="text-custom-blue">Loading...</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300 shadow-md rounded-lg">
          <thead className="bg-blue-100 text-custom-blue">
            <tr>
              <th className="py-3 px-4 font-times border-b text-left">Check-in</th>
              <th className="py-3 px-4 border-b font-timestext-left">Check-out</th>
              <th className="py-3 px-4 border-b font-times text-left">Rooms</th>
              <th className="py-3 px-4 border-b font-times text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {bookings.map((booking) => (
              <tr key={booking.id} className="border-b font-times  hover:bg-gray-50">
                <td className="py-3 px-4">{booking.checkIn.toDate().toLocaleDateString()}</td>
                <td className="py-3 font-times px-4">{booking.checkOut.toDate().toLocaleDateString()}</td>
                <td className="py-3 font-times px-4">
                  <ul className="list-disc list-inside">
                    {booking.rooms.map((room, index) => (
                      <li key={index}>{room.type}: {room.quantity}</li>
                    ))}
                  </ul>
                </td>
                <td className="py-3 px-4 text-right">
                  <Link
                    to={`/edit-booking/${booking.id}`}
                    className="text-yellow-600 hover:underline mr-4"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => handleDelete(booking.id)}
                    className="text-red-600 hover:underline"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageBookings;
