



// import React, { useState, useEffect } from 'react';
// import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
// import { db } from '../components/firebase'; // Ensure correct import path
// import { useAuth } from '../components/authcontext'; // Ensure correct import path
// import { Link, Navigate } from 'react-router-dom';

// const Admin = () => {
//   const { currentUser, logout } = useAuth();
//   const [reservations, setReservations] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [deleteConfirmation, setDeleteConfirmation] = useState(null);
//   const [currentTime, setCurrentTime] = useState(new Date());

//   useEffect(() => {
//     const fetchReservations = async () => {
//       setLoading(true);
//       try {
//         const querySnapshot = await getDocs(collection(db, 'reservations'));
//         const reservationsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setReservations(reservationsList);
//       } catch (error) {
//         setError('Failed to fetch reservations. Please try again later.');
//         console.error('Error fetching reservations:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const intervalId = setInterval(() => {
//       setCurrentTime(new Date());
//     }, 1000);

//     fetchReservations();

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, []);

//   const handleDelete = async (id) => {
//     try {
//       if (!window.confirm('Are you sure you want to delete this reservation?')) {
//         return;
//       }

//       setLoading(true);
//       await deleteDoc(doc(db, 'reservations', id));
//       setReservations(prevReservations => prevReservations.filter(reservation => reservation.id !== id));
//       setDeleteConfirmation('Reservation deleted successfully');
//       setTimeout(() => {
//         setDeleteConfirmation(null);
//       }, 3000);
//     } catch (error) {
//       console.error('Error deleting reservation: ', error);
//       setError('Failed to delete reservation. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const formatDate = (date) => {
//     if (!date) return 'Date not available';

//     // Convert Firestore Timestamp to JavaScript Date
//     if (date.toDate) {
//       date = date.toDate();
//     }

//     // Check if date is a valid Date object
//     if (!(date instanceof Date) || isNaN(date.getTime())) {
//       return 'Invalid date';
//     }

//     return date.toLocaleDateString(); // Return only the date part
//   };

//   const getTimePassed = (timestamp) => {
//     if (!timestamp) {
//       return 'Timestamp not available';
//     }

//     // Convert Firestore Timestamp to JavaScript Date
//     if (timestamp.toDate) {
//       timestamp = timestamp.toDate();
//     }

//     // Check if timestamp is a valid Date object
//     if (!(timestamp instanceof Date) || isNaN(timestamp.getTime())) {
//       return 'Invalid timestamp';
//     }

//     const difference = currentTime - timestamp;
//     const secondsPassed = Math.floor(difference / 1000);
//     const minutesPassed = Math.floor(secondsPassed / 60);
//     const hoursPassed = Math.floor(minutesPassed / 60);

//     if (hoursPassed > 0) {
//       return `${hoursPassed} ${hoursPassed === 1 ? 'hour' : 'hours'} ago`;
//     } else if (minutesPassed > 0) {
//       return `${minutesPassed} ${minutesPassed === 1 ? 'minute' : 'minutes'} ago`;
//     } else {
//       return `${secondsPassed} ${secondsPassed === 1 ? 'second' : 'seconds'} ago`;
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await logout();
//     } catch (error) {
//       console.error('Logout failed:', error);
//     }
//   };

//   if (!currentUser) {
//     return <Navigate to="/login" replace />;
//   }

//   return (
//     <div className="bg-gray-100 min-h-screen p-4 sm:p-8">
//       <header className="bg-white py-4 mb-4 sm:mb-8 shadow">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
//           <Link to="/book" className="text-blue-500 hover:text-blue-700 font-bold font-times">
//             Register Booking
//           </Link>
//           <Link to="/manage" className="text-blue-500 hover:text-blue-700 font-bold font-times">
//             Booked Rooms
//           </Link>
//           <h2 className="text-3xl font-times text-gray-800">Reservations</h2>
//           <button onClick={handleLogout} className="ml-4 text-red-500 hover:text-red-700 font-bold font-times">Logout</button>
//         </div>
//       </header>
//       <section className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
//         {loading && (
//           <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50">
//             <div className="spinner-border text-primary" role="status">
//               <span className="sr-only">Loading...</span>
//             </div>
//           </div>
//         )}
//         {error && <div className="text-red-500">{error}</div>}
//         {deleteConfirmation && (
//           <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
//             {deleteConfirmation}
//           </div>
//         )}
//         <div className="overflow-x-auto">
//           <div className="bg-white shadow-md rounded my-4 sm:my-6">
//             <table className="min-w-full bg-white">
//               <thead className="bg-custom-blue text-white">
//                 <tr>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Name</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Email</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Phone</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Room Type</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">CheckIn</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">CheckOut</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Time Passed</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Adults</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Children</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Actions</th>
//                 </tr>
//               </thead>
//               <tbody className="text-gray-700">
//                 {reservations.map((reservation) => (
//                   <tr key={reservation.id} className="bg-gray-100 hover:bg-gray-200">
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.name}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.email}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.phone}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.roomType}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{formatDate(reservation.checkIn)}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{formatDate(reservation.checkOut)}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{getTimePassed(reservation.timestamp)}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.adults}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.children}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">
//                       <button
//                         className="text-red-500 hover:text-red-700 font-bold font-times"
//                         onClick={() => handleDelete(reservation.id)}
//                       >
//                         Delete
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Admin;



// import React, { useState, useEffect } from 'react';
// import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
// import { db } from '../components/firebase'; // Ensure correct import path
// import { useAuth } from '../components/authcontext'; // Ensure correct import path
// import { Link, Navigate } from 'react-router-dom';
// import { FaSearch } from 'react-icons/fa';

// const Admin = () => {
//   const { currentUser, logout } = useAuth();
//   const [reservations, setReservations] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const [deleteConfirmation, setDeleteConfirmation] = useState(null);
//   const [currentTime, setCurrentTime] = useState(new Date());
//   const [searchQuery, setSearchQuery] = useState('');

//   useEffect(() => {
//     const fetchReservations = async () => {
//       setLoading(true);
//       try {
//         const querySnapshot = await getDocs(collection(db, 'reservations'));
//         const reservationsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//         setReservations(reservationsList);
//       } catch (error) {
//         setError('Failed to fetch reservations. Please try again later.');
//         console.error('Error fetching reservations:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     const intervalId = setInterval(() => {
//       setCurrentTime(new Date());
//     }, 1000);

//     fetchReservations();

//     return () => {
//       clearInterval(intervalId);
//     };
//   }, []);

//   const handleDelete = async (id) => {
//     try {
//       if (!window.confirm('Are you sure you want to delete this reservation?')) {
//         return;
//       }

//       setLoading(true);
//       await deleteDoc(doc(db, 'reservations', id));
//       setReservations(prevReservations => prevReservations.filter(reservation => reservation.id !== id));
//       setDeleteConfirmation('Reservation deleted successfully');
//       setTimeout(() => {
//         setDeleteConfirmation(null);
//       }, 3000);
//     } catch (error) {
//       console.error('Error deleting reservation: ', error);
//       setError('Failed to delete reservation. Please try again.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const formatDate = (date) => {
//     if (!date) return 'Date not available';

//     // Convert Firestore Timestamp to JavaScript Date
//     if (date.toDate) {
//       date = date.toDate();
//     }

//     // Check if date is a valid Date object
//     if (!(date instanceof Date) || isNaN(date.getTime())) {
//       return 'Invalid date';
//     }

//     return date.toLocaleDateString(); // Return only the date part
//   };

//   const getTimePassed = (timestamp) => {
//     if (!timestamp) {
//       return 'Timestamp not available';
//     }

//     // Convert Firestore Timestamp to JavaScript Date
//     if (timestamp.toDate) {
//       timestamp = timestamp.toDate();
//     }

//     // Check if timestamp is a valid Date object
//     if (!(timestamp instanceof Date) || isNaN(timestamp.getTime())) {
//       return 'Invalid timestamp';
//     }

//     const difference = currentTime - timestamp;
//     const secondsPassed = Math.floor(difference / 1000);
//     const minutesPassed = Math.floor(secondsPassed / 60);
//     const hoursPassed = Math.floor(minutesPassed / 60);

//     if (hoursPassed > 0) {
//       return `${hoursPassed} ${hoursPassed === 1 ? 'hour' : 'hours'} ago`;
//     } else if (minutesPassed > 0) {
//       return `${minutesPassed} ${minutesPassed === 1 ? 'minute' : 'minutes'} ago`;
//     } else {
//       return `${secondsPassed} ${secondsPassed === 1 ? 'second' : 'seconds'} ago`;
//     }
//   };

//   const handleLogout = async () => {
//     try {
//       await logout();
//     } catch (error) {
//       console.error('Logout failed:', error);
//     }
//   };

//   if (!currentUser) {
//     return <Navigate to="/login" replace />;
//   }

//   const filteredReservations = reservations.filter(reservation =>
//     reservation.name.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <div className="bg-gray-100 min-h-screen p-4 sm:p-8">
//       <header className="bg-white py-4 mb-4 sm:mb-8 shadow">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
//           <Link to="/book" className="text-blue-500 hover:text-blue-700 font-bold font-times">
//             Register Booking
//           </Link>
//           <Link to="/manage" className="text-blue-500 hover:text-blue-700 font-bold font-times">
//             Booked Rooms
//           </Link>
//           <h2 className="text-3xl font-times text-gray-800">Reservations</h2>
//           <button onClick={handleLogout} className="ml-4 text-red-500 hover:text-red-700 font-bold font-times">Logout</button>
//         </div>
//       </header>
//       <section className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="flex justify-center mb-4">
//           <div className="relative">
//             <input
//               type="text"
//               placeholder="Search by name..."
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//               className="border-2 border-blue-500 p-2 rounded-lg pl-10 font-times"
//               style={{ width: '400px' }}
//             />
//             <FaSearch className="absolute top-3 left-3 text-blue-500" />
//           </div>
//         </div>
//         {loading && (
//           <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50">
//             <div className="spinner-border text-primary" role="status">
//               <span className="sr-only">Loading...</span>
//             </div>
//           </div>
//         )}
//         {error && <div className="text-red-500">{error}</div>}
//         {deleteConfirmation && (
//           <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
//             {deleteConfirmation}
//           </div>
//         )}
//         <div className="overflow-x-auto">
//           <div className="bg-white shadow-md rounded my-4 sm:my-6">
//             <table className="min-w-full bg-white">
//               <thead className="bg-custom-blue text-white">
//                 <tr>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Name</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Email</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Phone</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Room Type</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">CheckIn</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">CheckOut</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Time Passed</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Adults</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Children</th>
//                   <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Actions</th>
//                 </tr>
//               </thead>
//               <tbody className="text-gray-700">
//                 {filteredReservations.map((reservation) => (
//                   <tr key={reservation.id} className="bg-gray-100 hover:bg-gray-200">
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.name}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.email}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.phone}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.roomType}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{formatDate(reservation.checkIn)}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{formatDate(reservation.checkOut)}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{getTimePassed(reservation.timestamp)}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.adults}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.children}</td>
//                     <td className="py-3 px-4 sm:px-6 lg:px-8">
//                       <button
//                         className="text-red-500 hover:text-red-700 font-bold font-times"
//                         onClick={() => handleDelete(reservation.id)}
//                       >
//                         Delete
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Admin;
import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import {  Button  } from '@material-ui/core';
import { db } from '../components/firebase';
import { useAuth } from '../components/authcontext';
import { Link, Navigate } from 'react-router-dom';
import { FaSearch } from 'react-icons/fa';
import AddReservationDialog from './addreservation';

const Admin = () => {
  const { currentUser, logout } = useAuth();
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchReservations = async () => {
    setLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'reservations'));
      const reservationsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setReservations(reservationsList);
    } catch (error) {
      setError('Failed to fetch reservations. Please try again later.');
      console.error('Error fetching reservations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservations();

    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleDelete = async (id) => {
    try {
      if (!window.confirm('Are you sure you want to delete this reservation?')) {
        return;
      }

      setLoading(true);
      await deleteDoc(doc(db, 'reservations', id));
      setReservations(prevReservations => prevReservations.filter(reservation => reservation.id !== id));
      setDeleteConfirmation('Reservation deleted successfully');
      setTimeout(() => {
        setDeleteConfirmation(null);
      }, 3000);
    } catch (error) {
      console.error('Error deleting reservation: ', error);
      setError('Failed to delete reservation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    if (!date) return 'Date not available';
    if (date.toDate) {
      date = date.toDate();
    }

    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return 'Invalid date';
    }

    return date.toLocaleDateString(); 
  };

  const getTimePassed = (timestamp) => {
    if (!timestamp) {
      return 'Timestamp not available';
    }
    if (timestamp.toDate) {
      timestamp = timestamp.toDate();
    }

    if (!(timestamp instanceof Date) || isNaN(timestamp.getTime())) {
      return 'Invalid timestamp';
    }

    const difference = currentTime - timestamp;
    const secondsPassed = Math.floor(difference / 1000);
    const minutesPassed = Math.floor(secondsPassed / 60);
    const hoursPassed = Math.floor(minutesPassed / 60);

    if (hoursPassed > 0) {
      return `${hoursPassed} ${hoursPassed === 1 ? 'hour' : 'hours'} ago`;
    } else if (minutesPassed > 0) {
      return `${minutesPassed} ${minutesPassed === 1 ? 'minute' : 'minutes'} ago`;
    } else {
      return `${secondsPassed} ${secondsPassed === 1 ? 'second' : 'seconds'} ago`;
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  const filteredReservations = reservations.filter(reservation =>
    reservation.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="bg-gray-100 min-h-screen p-4 sm:p-8">
      <header className="bg-white py-4 mb-4 sm:mb-8 shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/book" className="text-blue-500 hover:text-blue-700 font-bold font-times">
            Register Booking
          </Link>
          <Link to="/manage" className="text-blue-500 hover:text-blue-700 font-bold font-times">
            Booked Rooms
          </Link>
          <h2 className="text-3xl font-times text-gray-800">Reservations</h2>
          <button onClick={handleLogout} className="ml-4 text-red-500 hover:text-red-700 font-bold font-times">Logout</button>
        </div>
      </header>
      <section className="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center mb-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="border-2 border-blue-500 p-2 rounded-lg pl-10 font-times"
              style={{ width: '400px' }}
            />
            <FaSearch className="absolute top-3 left-3 text-blue-500" />
          </div>
        </div>
        <div className="text-right mb-4">
          <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)}>
            Add Reservation
          </Button>
        </div>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-300 bg-opacity-50">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {error && <div className="text-red-500">{error}</div>}
        {deleteConfirmation && (
          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-4">
            {deleteConfirmation}
          </div>
        )}
        <div className="overflow-x-auto">
          <div className="bg-white shadow-md rounded my-4 sm:my-6">
            <table className="min-w-full bg-white">
              <thead className="bg-custom-blue text-white">
                <tr>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Name</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Email</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Phone</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Room Type</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">CheckIn</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">CheckOut</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Time Passed</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Adults</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Children</th>
                  <th className="w-1/6 py-3 px-4 sm:px-6 lg:px-8 uppercase font-times text-sm sm:text-base">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {filteredReservations.map((reservation) => (
                  <tr key={reservation.id} className="bg-gray-100 hover:bg-gray-200">
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.name}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.email}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.phone}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.roomType}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{formatDate(reservation.checkIn)}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{formatDate(reservation.checkOut)}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{getTimePassed(reservation.timestamp)}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.adults}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">{reservation.children}</td>
                    <td className="py-3 px-4 sm:px-6 lg:px-8">
                      <button
                        className="text-red-500 hover:text-red-700 font-bold font-times"
                        onClick={() => handleDelete(reservation.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <AddReservationDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onAdd={fetchReservations}
        />
      </section>
    </div>
  );
};

export default Admin;
