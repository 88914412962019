import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/logo.jpg';

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-white text-custom-blue border-b-2 border-white sticky top-0">
      <div className="flex items-center justify-between h-16 px-6">
        <div className="flex items-center space-x-2">
          <div className="flex-shrink-0">
            <img src={logo} alt="Caravan Dire" className="h-16 w-auto" />
          </div>
        </div>
        <div className="hidden md:flex flex-1 justify-end">
          <div className="flex space-x-6">
            <NavLink
              exact
              to="/"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/rooms"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              Rooms
            </NavLink>
            <NavLink
              to="/restaurant"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              Restaurant
            </NavLink>
            <NavLink
              to="/services"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              Services
            </NavLink>
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/gallery"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              Gallery
            </NavLink>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `text-custom-blue px-3 py-2  text-lg font-times ${isActive ? 'underline' : 'hover:text-custom-blbg-custom-blue'}`
              }
            >
              About Us
            </NavLink>
          </div>
        </div>
        <div className="md:hidden flex items-center space-x-4">
          <button onClick={toggleMenu} className="text-custom-blue focus:outline-none">
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="w-6 h-6" />
          </button>
          <div className="flex items-center space-x-2">
            <span role="img" aria-label="phone" className="text-custom-blue">
              📞
            </span>
            <span className="text-lg text-custom-blue">0254114312</span>
          </div>
        </div>
      </div>
      <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <NavLink
            exact
            to="/"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            Home
          </NavLink>
          <NavLink
            to="/rooms"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            Rooms
          </NavLink>
          <NavLink
            to="/restaurant"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            Restaurant
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            Services
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            Contact Us
          </NavLink>
          <NavLink
            to="/gallery"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            Gallery
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `block text-custom-blue px-3 py-2  text-base font-times ${isActive ? 'bg-custom-blue text-white' : 'hover:bg-blue-100'}`
            }
            onClick={closeMenu}
          >
            About Us
          </NavLink>
          <div className="flex items-center space-x-2 mt-4 text-custom-blue">
            <span role="img" aria-label="phone">📞</span>
            <span className="text-lg font-times">0254114312</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
