import React from 'react';

const InternetService = () => {
  return (
    <div>
      <h1>Internet Service</h1>
      <p>Details about Internet service...</p>
    </div>
  );
};

export default InternetService;
