import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReservationForm from '../components/reservation';
import RoomCalendar from '../components/calender';
import room1 from '../assets/standard.jpg';
import room2 from '../assets/twin1.jpg';
import room3 from '../assets/semi1.jpg';
import room4 from '../assets/sweet1.jpg';

import twin1 from '../assets/twin2.jpg';
import twin2 from '../assets/twin3.jpg';
import twin3 from '../assets/twin4.jpg';
import twin4 from '../assets/twin5.jpg';
import twin5 from '../assets/twin6.jpg';

import sweet1 from '../assets/sweet.jpg';
import sweet2 from '../assets/sweet2.jpg';
import sweet3 from '../assets/sweet4.jpg';
import sweet4 from '../assets/sweet5.jpg';
import sweet5 from '../assets/sweet6.jpg';

import standard1 from '../assets/standard1.jpg';
import standard2 from '../assets/standard2.jpg';
import tabel from '../assets/semi2.jpg';

import semi1 from '../assets/semi3.jpg';
import semi2 from '../assets/semi4.jpg';
import semi3 from '../assets/semi5.jpg';
import semi4 from '../assets/semi6.jpg';
import semi5 from '../assets/semi7.jpg';
import semi6 from '../assets/semi8.jpg';

const roomDetails = [
  {
    id: 1,
    name: "Standard Room",
    image: room1,
    gallery: [standard1, standard2, tabel],
    price: "1500/Night",
    description: "Experience comfort and convenience in our Standard Rooms, designed with your relaxation in mind. These cozy rooms feature a plush bed, modern amenities, and a serene ambiance, offering the perfect retreat after a day of exploration. Ideal for solo travelers or couples seeking a peaceful stay.",
    characteristics: [
      { icon: "👥", label: "Accommodates 2 Persons" },
      { icon: "📶", label: "Free High-Speed Wifi" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🍽️", label: "Complimentary Breakfast" },
      { icon: "🛁", label: "Luxury Towels" },
      { icon: "📺", label: "Smart TV with Streaming" },
      { icon: "🪑", label: "Workspace with Table and Chair" },
      { icon: "🏞️", label: "Private Patio with Garden Views" },
    ],
    policies: {
      checkIn: "Flexible Check-in",
      checkOut: "Check-out by 11 AM",
      pets: "Pets are not allowed.",
    },
  },
  {
    id: 2,
    name: "TwinBed Room",
    image: room2,
    gallery: [twin1, twin2, twin3, twin4, twin5, tabel],
    price: "1800/Night",
    description: "Unwind in our spacious Twin Bed Rooms, perfect for up to four guests. Featuring twin beds and a comfortable seating area, these rooms offer panoramic views and ample space for relaxation. Whether traveling with family or friends, our Twin Bed Rooms ensure a memorable stay.",
    characteristics: [
      { icon: "👥", label: "Accommodates 2-4 Persons" },
      { icon: "🛏️", label: "Two Comfortable Twin Beds" },
      { icon: "📶", label: "Free High-Speed Wifi" },
      { icon: "🍽️", label: "Complimentary Breakfast" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🛁", label: "Luxury Towels" },
      { icon: "📺", label: "Smart TV with Streaming" },
      { icon: "🪑", label: "Work Desk with Ergonomic Chair" },
      { icon: "🏞️", label: "Private Balcony with Scenic Views" },
    ],
    policies: {
      checkIn: "Flexible Check-in",
      checkOut: "Check-out by 11 AM",
      pets: "Pets are not allowed.",
    },
  },
  {
    id: 3,
    name: "SemiSweet Room",
    image: room3,
    gallery: [semi1, semi2, semi3, semi4, semi5, semi6, tabel],
    price: "1600/Night",
    description: "Indulge in comfort with our Semi-Sweet Rooms, offering extra space and thoughtful amenities for your stay. These rooms include a convenient cooking space, perfect for preparing light meals, along with all essential amenities. Ideal for guests seeking comfort and functionality during their visit.",
    characteristics: [
      { icon: "👥", label: "Accommodates 1-2 Persons" },
      { icon: "📶", label: "Free High-Speed Wifi" },
      { icon: "🍽️", label: "Complimentary Breakfast" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🍳", label: "Convenient Cooking Space" },
      { icon: "🛁", label: "Luxury Towels" },
      { icon: "📺", label: "Smart TV with Streaming" },
      { icon: "🪑", label: "Workstation with Table and Chair" },
      { icon: "🏞️", label: "Private Terrace with Garden Views" },
    ],
    policies: {
      checkIn: "Flexible Check-in",
      checkOut: "Check-out by 11 AM",
      pets: "Pets are not allowed.",
    },
  },
  {
    id: 4,
    name: "Sweet Room",
    image: room4,
    gallery: [sweet1, sweet2, sweet3, sweet4, sweet5, tabel],
    price: "3000/Night",
    description: "Indulge in our spacious Sweet Rooms, perfect for families. Enjoy separate living and sleeping areas, two bathrooms, air conditioning, and a full kitchen. Luxurious amenities ensure a relaxing stay for all.",
    characteristics: [
      { icon: "👨‍👩‍👧‍👦", label: "Perfect for Families" },
      { icon: "📶", label: "Free High-Speed Wifi" },
      { icon: "🍽️", label: "Complimentary Breakfast" },
      { icon: "🛋️", label: "Separate Cozy Salon" },
      { icon: "🛌", label: "Private Bedroom Retreat" },
      { icon: "🚽", label: "Two Elegant Bathrooms" },
      { icon: "❄️", label: "Air Conditioning" },
      { icon: "🍳", label: "Fully Equipped Kitchen" },
      { icon: "🛁", label: "Luxury Towels" },
      { icon: "📺", label: "Smart TV with Streaming" },
      { icon: "🪑", label: "Dining Area with Table and Chairs" },
      { icon: "🏞️", label: "Private Patio with Scenic Views" },
    ],
    policies: {
      checkIn: "Flexible Check-in",
      checkOut: "Check-out by 11 AM",
      pets: "Pets are not allowed.",
    },
  }
  
];

const RoomDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { checkInDate, checkOutDate,children,adults } = location.state || {};

  const room = roomDetails.find(room => room.id === parseInt(id));

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

  const handleReservationSuccess = () => {
    setIsSuccessDialogOpen(true);
    setIsModalOpen(false);
  };

  if (!room) {
    return <div>Room not found</div>;
  }

  return (
    <div className="bg-white min-h-screen py-4">
      <header className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-3xl text-custom-blue font-times">{room.name}</h1>
        </div>
      </header>
      <section className="py-8">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow-md overflow-hidden mb-8 relative">
            <img src={room.image} alt={room.name} className="w-full h-96 object-cover" />
            <div className="absolute inset-0"></div> {/* Blue overlay */}
          </div>

          <section className="mb-8">
            <div className="flex overflow-x-auto space-x-4">
              {room.gallery.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Gallery ${index + 1}`}
                  className="w-64 h-64 object-cover shadow-md cursor-pointer"
                  onClick={() => {
                    setPhotoIndex(index);
                    setIsOpen(true);
                  }}
                />
              ))}
            </div>
            {isOpen && (
              <Lightbox
                mainSrc={room.gallery[photoIndex]}
                nextSrc={room.gallery[(photoIndex + 1) % room.gallery.length]}
                prevSrc={room.gallery[(photoIndex + room.gallery.length - 1) % room.gallery.length]}
                onCloseRequest={() => setIsOpen(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + room.gallery.length - 1) % room.gallery.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % room.gallery.length)
                }
              />
            )}
          </section>

          <div className="bg-white overflow-hidden mb-8">
            <div className="p-8">
              <p className="text-custom-blue font-times mb-6 text-lg">{room.description}</p>
              <div className="mb-4">
                <h3 className="text-2xl md:text-2xl text-custom-blue font-times mb-2">Amenities:</h3>
                <div className="grid grid-cols-1 text-custom-blue md:grid-cols-2 lg:grid-cols-3 gap-2">
                  {room.characteristics.map((characteristic, idx) => (
                    <div key={idx} className="flex items-center font-times space-x-2 mb-2">
                      <span role="img" aria-label="characteristic-icon">{characteristic.icon}</span>
                      <span>{characteristic.label}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-right">
                <p className="text-xl font-times font-bold text-custom-blue">Price: {room.price}</p>
              </div>

              <div className="mt-6 p-4 bg-blue-50 rounded-lg shadow-md">
                <h3 className="text-2xl md:text-2xl font-times mb-4 text-custom-blue">Rules</h3>
                <ul className="list-none space-y-3 text-gray-900 font-times text-lg">
                  <li className="flex items-center">
                    <span className="mr-2 text-blue-500">🕓</span>
                    <span className='text-custom-blue'>{room.policies.checkIn}</span>
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2 text-blue-500">🚪</span>
                    <span className='text-custom-blue'>{room.policies.checkOut}</span>
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2 text-blue-500">💵</span>
                    <span className='text-custom-blue'>
                      Pay at the property -{" "}
                      <span className="text-sm text-red-600">
                        Payment should be made within 24 hours after reservation, otherwise the reservation will be canceled.
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center">
                    <span className="mr-2 text-blue-500">🐾</span>
                    <span className='text-custom-blue'>{room.policies.pets}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <RoomCalendar roomId={room.id} /> {/* Add the RoomCalendar component here */}
          </div>

          <div className="text-right mt-4">
            <button
              className="bg-custom-blue text-white font-times py-2 px-4 rounded-full text-lg hover:bg-blue-600 focus:outline-none"
              onClick={() => setIsModalOpen(true)}
            >
              Make Reservation
            </button>
          </div>

          {isModalOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full h-full overflow-y-auto">
                <div className="max-w-lg mx-auto" onClick={(e) => e.stopPropagation()}>
                  <ReservationForm
                    room={room}
                    onSuccess={handleReservationSuccess}
                    onClose={() => setIsModalOpen(false)}
                    checkInDate={checkInDate}
                    checkOutDate={checkOutDate}
                    children ={children}
                    adults ={adults}
                    
                  />
                </div>
              </div>
            </div>
          )}

          {isSuccessDialogOpen && (
   <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
   <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
     <h2 className="text-2xl font-times mb-4 text-green-500">Reservation Successful</h2>
     <p className="mb-4 font-times text-sm">You have successfully reserved your room.</p>
     <p className="mb-4 font-times text-sm">Our team will contact you soon. Thank You</p>
     <p className="mb-4 font-times text-sm text-red-600">Note: Reservation will be canceled after 4 hours if payment is not completed. Make payment at the property.</p>
     <button
       className="w-full bg-custom-blue text-white py-2 font-times rounded-custom hover:bg-blue-600 transition duration-200"
       onClick={() => setIsSuccessDialogOpen(false)}
     >
       OK
     </button>
   </div>
 </div>
 
          )}
        </div>
      </section>
    </div>
  );
}

export default RoomDetail;




// import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';
// import { db } from '../components/firebase';
// import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
// import ReservationForm from '../components/reservation';
// import RoomCalendar from '../components/calender';
// import room1 from '../assets/standard.jpg';
// import room2 from '../assets/twin1.jpg';
// import room3 from '../assets/semi1.jpg';
// import room4 from '../assets/sweet1.jpg';

// import twin1 from '../assets/twin2.jpg';
// import twin2 from '../assets/twin3.jpg';
// import twin3 from '../assets/twin4.jpg';
// import twin4 from '../assets/twin5.jpg';
// import twin5 from '../assets/twin6.jpg';

// import sweet1 from '../assets/sweet.jpg';
// import sweet2 from '../assets/sweet2.jpg';
// import sweet3 from '../assets/sweet4.jpg';
// import sweet4 from '../assets/sweet5.jpg';
// import sweet5 from '../assets/sweet6.jpg';

// import standard1 from '../assets/standard1.jpg';
// import standard2 from '../assets/standard2.jpg';
// import tabel from '../assets/semi2.jpg';

// import semi1 from '../assets/semi3.jpg';
// import semi2 from '../assets/semi4.jpg';
// import semi3 from '../assets/semi5.jpg';
// import semi4 from '../assets/semi6.jpg';
// import semi5 from '../assets/semi7.jpg';
// import semi6 from '../assets/semi8.jpg';

// const roomDetails = [
//   {
//     id: 1,
//     name: "Standard Room",
//     image: room1,
//     gallery: [standard1, standard2, tabel],
//     price: "1500/Night",
//     description: "Experience comfort and convenience in our Standard Rooms, designed with your relaxation in mind. These cozy rooms feature a plush bed, modern amenities, and a serene ambiance, offering the perfect retreat after a day of exploration. Ideal for solo travelers or couples seeking a peaceful stay.",
//     characteristics: [
//       { icon: "👥", label: "Accommodates 2 Persons" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Workspace with Table and Chair" },
//       { icon: "🏞️", label: "Private Patio with Garden Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   },
//   {
//     id: 2,
//     name: "TwinBed Room",
//     image: room2,
//     gallery: [twin1, twin2, twin3, twin4, twin5, tabel],
//     price: "1800/Night",
//     description: "Unwind in our spacious Twin Bed Rooms, perfect for up to four guests. Featuring twin beds and a comfortable seating area, these rooms offer panoramic views and ample space for relaxation. Whether traveling with family or friends, our Twin Bed Rooms ensure a memorable stay.",
//     characteristics: [
//       { icon: "👥", label: "Accommodates 2-4 Persons" },
//       { icon: "🛏️", label: "Two Comfortable Twin Beds" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Work Desk with Ergonomic Chair" },
//       { icon: "🏞️", label: "Private Balcony with Scenic Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   },
//   {
//     id: 3,
//     name: "SemiSweet Room",
//     image: room3,
//     gallery: [semi1, semi2, semi3, semi4, semi5, semi6, tabel],
//     price: "1600/Night",
//     description: "Indulge in comfort with our Semi-Sweet Rooms, offering extra space and thoughtful amenities for your stay. These rooms include a convenient cooking space, perfect for preparing light meals, along with all essential amenities. Ideal for guests seeking comfort and functionality during their visit.",
//     characteristics: [
//       { icon: "👥", label: "Accommodates 1-2 Persons" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🍳", label: "Convenient Cooking Space" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Workstation with Table and Chair" },
//       { icon: "🏞️", label: "Private Terrace with Garden Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   },
//   {
//     id: 4,
//     name: "Sweet Room",
//     image: room4,
//     gallery: [sweet1, sweet2, sweet3, sweet4, sweet5, tabel],
//     price: "3000/Night",
//     description: "Indulge in our spacious Sweet Rooms, perfect for families. Enjoy separate living and sleeping areas, two bathrooms, air conditioning, and a full kitchen. Luxurious amenities ensure a relaxing stay for all.",
//     characteristics: [
//       { icon: "👨‍👩‍👧‍👦", label: "Perfect for Families" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "🛋️", label: "Separate Cozy Salon" },
//       { icon: "🛌", label: "Private Bedroom Retreat" },
//       { icon: "🚽", label: "Two Elegant Bathrooms" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🍳", label: "Fully Equipped Kitchen" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Dining Area with Table and Chairs" },
//       { icon: "🏞️", label: "Private Patio with Scenic Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   }
  
// ];

// const RoomDetail = () => {
//   const { id } = useParams();
//   const room = roomDetails.find(room => room.id === parseInt(id));

//   const [isOpen, setIsOpen] = useState(false);
//   const [photoIndex, setPhotoIndex] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
//   const [availabilityChecked, setAvailabilityChecked] = useState(false);
//   const [isRoomAvailable, setIsRoomAvailable] = useState(false);
//   const [checkInDate, setCheckInDate] = useState('');
//   const [checkOutDate, setCheckOutDate] = useState('');
//   const [adults, setAdults] = useState(1);
//   const [children, setChildren] = useState(0);



//   const handleCheckAvailability = async () => {
//     if (!checkInDate || !checkOutDate) {
//       alert('Please select both check-in and check-out dates.');
//       return;
//     }

//     try {
//       const checkInTimestamp = new Date(checkInDate);
//       const checkOutTimestamp = new Date(checkOutDate);

//       // Fetch booking and reservation data
//       const bookingQuery = query(
//         collection(db, 'bookings'),
//         where('checkIn', '<=', checkOutTimestamp),
//         where('checkOut', '>=', checkInTimestamp),
//         where('roomType', '==', room.name)
//       );
//       const reservationQuery = query(
//         collection(db, 'reservations'),
//         where('checkIn', '<=', checkOutTimestamp),
//         where('checkOut', '>=', checkInTimestamp),
//         where('roomType', '==', room.name)
//       );

//       const [bookingSnapshot, reservationSnapshot] = await Promise.all([
//         getDocs(bookingQuery),
//         getDocs(reservationQuery)
//       ]);

//       const bookings = bookingSnapshot.docs.map(doc => doc.data());
//       const reservations = reservationSnapshot.docs.map(doc => doc.data());

//       // Calculate total bookings and reservations
//       const totalBooked = bookings.reduce((acc, booking) => acc + booking.quantity, 0);
//       const totalReserved = reservations.reduce((acc, reservation) => acc + reservation.quantity, 0);

//       const totalAvailableRooms = roomDetails.find(r => r.name === room.name).total - totalBooked - totalReserved;

//       setAvailabilityChecked(true);
//       setIsRoomAvailable(totalAvailableRooms > 0);

//     } catch (error) {
//       console.error("Error checking room availability: ", error);
//     }
//   };

//   const handleReservationSuccess = () => {
//     setIsSuccessDialogOpen(true);
//     setIsModalOpen(false);
//   };

//   if (!room) {
//     return <div>Room not found</div>;
//   }

//   return (
//     <div className="bg-white min-h-screen py-4">
//       <header className="bg-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
//           <h1 className="text-3xl text-custom-blue font-times">{room.name}</h1>
//         </div>
//       </header>
//       <section className="py-8">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="bg-white shadow-md overflow-hidden mb-8 relative">
//             <img src={room.image} alt={room.name} className="w-full h-96 object-cover" />
//             <div className="absolute inset-0"></div> {/* Blue overlay */}
//           </div>

//           <section className="mb-8">
//             <div className="flex overflow-x-auto space-x-4">
//               {room.gallery.map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt={`Gallery ${index + 1}`}
//                   className="w-64 h-64 object-cover shadow-md cursor-pointer"
//                   onClick={() => {
//                     setPhotoIndex(index);
//                     setIsOpen(true);
//                   }}
//                 />
//               ))}
//             </div>
//             {isOpen && (
//               <Lightbox
//                 mainSrc={room.gallery[photoIndex]}
//                 nextSrc={room.gallery[(photoIndex + 1) % room.gallery.length]}
//                 prevSrc={room.gallery[(photoIndex + room.gallery.length - 1) % room.gallery.length]}
//                 onCloseRequest={() => setIsOpen(false)}
//                 onMovePrevRequest={() =>
//                   setPhotoIndex((photoIndex + room.gallery.length - 1) % room.gallery.length)
//                 }
//                 onMoveNextRequest={() =>
//                   setPhotoIndex((photoIndex + 1) % room.gallery.length)
//                 }
//               />
//             )}
//           </section>

//           <div className="bg-white overflow-hidden mb-8">
//             <div className="p-8">
//               <p className="text-custom-blue font-times mb-6 text-lg">{room.description}</p>
//               <div className="mb-4">
//                 <h3 className="text-2xl md:text-2xl text-custom-blue font-times mb-2">Amenities:</h3>
//                 <div className="grid grid-cols-1 text-custom-blue md:grid-cols-2 lg:grid-cols-3 gap-2">
//                   {room.characteristics.map((characteristic, idx) => (
//                     <div key={idx} className="flex items-center font-times space-x-2 mb-2">
//                       <span role="img" aria-label="characteristic-icon">{characteristic.icon}</span>
//                       <span>{characteristic.label}</span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//               <div className="text-right">
//                 <p className="text-xl font-times font-bold text-custom-blue">Price: {room.price}</p>
//               </div>

//               <div className="mt-6 p-4 bg-blue-50 rounded-lg shadow-md">
//                 <h3 className="text-2xl md:text-2xl font-times mb-4 text-custom-blue">Rules</h3>
//                 <ul className="list-none space-y-3 text-gray-900 font-times text-lg">
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">🕓</span>
//                     <span className='text-custom-blue'>{room.policies.checkIn}</span>
//                   </li>
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">🚪</span>
//                     <span className='text-custom-blue'>{room.policies.checkOut}</span>
//                   </li>
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">💵</span>
//                     <span className='text-custom-blue'>
//                       Pay at the property -{" "}
//                       <span className="text-sm text-red-600">
//                         Payment should be made within 24 hours after reservation, otherwise the reservation will be canceled.
//                       </span>
//                     </span>
//                   </li>
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">🐾</span>
//                     <span className='text-custom-blue'>{room.policies.pets}</span>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="mb-8">
//             <RoomCalendar roomId={room.id} /> {/* Add the RoomCalendar component here */}
//           </div>

//           <div className="text-right mt-4">
//             <div className="flex flex-col space-y-2">
//               {!availabilityChecked ? (
//                 <>
//                   <div className="flex flex-col space-y-2">
//                     <label className="font-times text-gray-700">Check-in Date:</label>
//                     <input
//                       type="date"
//                       value={checkInDate}
//                       onChange={(e) => setCheckInDate(e.target.value)}
//                       className="border-2 border-blue-500 p-2 rounded-lg"
//                       min={new Date().toISOString().split('T')[0]}
//                     />
//                   </div>
//                   <div className="flex flex-col space-y-2">
//                     <label className="font-times text-gray-700">Check-out Date:</label>
//                     <input
//                       type="date"
//                       value={checkOutDate}
//                       onChange={(e) => setCheckOutDate(e.target.value)}
//                       className="border-2 border-blue-500 p-2 rounded-lg"
//                       min={checkInDate ? checkInDate : new Date().toISOString().split('T')[0]}
//                     />
//                   </div>
//                   <div className="flex flex-col space-y-2">
//                     <label className="font-times text-gray-700">Adults:</label>
//                     <input
//                       type="number"
//                       min="1"
//                       value={adults}
//                       onChange={(e) => setAdults(Number(e.target.value))}
//                       className="border-2 border-blue-500 p-2 rounded-lg"
//                     />
//                   </div>
//                   <div className="flex flex-col space-y-2">
//                     <label className="font-times text-gray-700">Children:</label>
//                     <input
//                       type="number"
//                       min="0"
//                       value={children}
//                       onChange={(e) => setChildren(Number(e.target.value))}
//                       className="border-2 border-blue-500 p-2 rounded-lg"
//                     />
//                   </div>
//                   <button
//                     className="bg-custom-blue text-white font-times py-2 px-4 rounded-full text-lg hover:bg-blue-600 focus:outline-none mt-4"
//                     onClick={handleCheckAvailability}
//                   >
//                     Check Availability
//                   </button>
//                 </>
//               ) : isRoomAvailable ? (
//                 <button
//                   className="bg-custom-blue text-white font-times py-2 px-4 rounded-full text-lg hover:bg-blue-600 focus:outline-none mt-4"
//                   onClick={() => setIsModalOpen(true)}
//                 >
//                   Make Reservation
//                 </button>
//               ) : (
//                 <p className="text-red-500 font-times mt-4">No available rooms found for the selected dates.</p>
//               )}
//             </div>
//           </div>

//           {isModalOpen && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//               <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full h-full overflow-y-auto">
//                 <div className="max-w-lg mx-auto" onClick={(e) => e.stopPropagation()}>
//                   <ReservationForm
//                     room={room}
//                     onSuccess={handleReservationSuccess}
//                     onClose={() => setIsModalOpen(false)}
//                     checkInDate={checkInDate}
//                     checkOutDate={checkOutDate}
//                     children={children}
//                     adults={adults}
//                   />
//                 </div>
//               </div>
//             </div>
//           )}

//           {isSuccessDialogOpen && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//               <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
//                 <h2 className="text-2xl font-times mb-4 text-green-500">Reservation Successful</h2>
//                 <p className="mb-4 font-times text-sm">You have successfully reserved your room.</p>
//                 <p className="mb-4 font-times text-sm">Our team will contact you soon. Thank You</p>
//                 <p className="mb-4 font-times text-sm text-red-600">Note: Reservation will be canceled after 4 hours if payment is not completed. Make payment at the property.</p>
//                 <button
//                   className="w-full bg-custom-blue text-white py-2 font-times rounded-custom hover:bg-blue-600 transition duration-200"
//                   onClick={() => setIsSuccessDialogOpen(false)}
//                 >
//                   OK
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>
//     </div>
//   );
// }

// export default RoomDetail;




// import React, { useState, useEffect } from 'react';
// import { useParams, useLocation } from 'react-router-dom';
// import Lightbox from 'react-image-lightbox';
// import 'react-image-lightbox/style.css';
// import { db } from '../components/firebase';
// import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';
// import ReservationForm from '../components/reservation';
// import RoomCalendar from '../components/calender';
// import room1 from '../assets/standard.jpg';
// import room2 from '../assets/twin1.jpg';
// import room3 from '../assets/semi1.jpg';
// import room4 from '../assets/sweet1.jpg';

// import twin1 from '../assets/twin2.jpg';
// import twin2 from '../assets/twin3.jpg';
// import twin3 from '../assets/twin4.jpg';
// import twin4 from '../assets/twin5.jpg';
// import twin5 from '../assets/twin6.jpg';

// import sweet1 from '../assets/sweet.jpg';
// import sweet2 from '../assets/sweet2.jpg';
// import sweet3 from '../assets/sweet4.jpg';
// import sweet4 from '../assets/sweet5.jpg';
// import sweet5 from '../assets/sweet6.jpg';

// import standard1 from '../assets/standard1.jpg';
// import standard2 from '../assets/standard2.jpg';
// import tabel from '../assets/semi2.jpg';

// import semi1 from '../assets/semi3.jpg';
// import semi2 from '../assets/semi4.jpg';
// import semi3 from '../assets/semi5.jpg';
// import semi4 from '../assets/semi6.jpg';
// import semi5 from '../assets/semi7.jpg';
// import semi6 from '../assets/semi8.jpg';

// const roomDetails = [
//   {
//     id: 1,
//     name: "Standard Room",
//     image: room1,
//     gallery: [standard1, standard2, tabel],
//     price: "1500/Night",
//     total: 20, // Add this field to indicate the total number of rooms available
//     description: "Experience comfort and convenience in our Standard Rooms, designed with your relaxation in mind. These cozy rooms feature a plush bed, modern amenities, and a serene ambiance, offering the perfect retreat after a day of exploration. Ideal for solo travelers or couples seeking a peaceful stay.",
//     characteristics: [
//       { icon: "👥", label: "Accommodates 2 Persons" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Workspace with Table and Chair" },
//       { icon: "🏞️", label: "Private Patio with Garden Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   },
//   {
//     id: 2,
//     name: "TwinBed Room",
//     image: room2,
//     gallery: [twin1, twin2, twin3, twin4, twin5, tabel],
//     price: "1800/Night",
//     total: 10,
//     description: "Unwind in our spacious Twin Bed Rooms, perfect for up to four guests. Featuring twin beds and a comfortable seating area, these rooms offer panoramic views and ample space for relaxation. Whether traveling with family or friends, our Twin Bed Rooms ensure a memorable stay.",
//     characteristics: [
//       { icon: "👥", label: "Accommodates 2-4 Persons" },
//       { icon: "🛏️", label: "Two Comfortable Twin Beds" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Work Desk with Ergonomic Chair" },
//       { icon: "🏞️", label: "Private Balcony with Scenic Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   },
//   {
//     id: 3,
//     name: "SemiSweet Room",
//     image: room3,
//     gallery: [semi1, semi2, semi3, semi4, semi5, semi6, tabel],
//     price: "1600/Night",
//     total: 8,
//     description: "Indulge in comfort with our Semi-Sweet Rooms, offering extra space and thoughtful amenities for your stay. These rooms include a convenient cooking space, perfect for preparing light meals, along with all essential amenities. Ideal for guests seeking comfort and functionality during their visit.",
//     characteristics: [
//       { icon: "👥", label: "Accommodates 1-2 Persons" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🍳", label: "Convenient Cooking Space" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Workstation with Table and Chair" },
//       { icon: "🏞️", label: "Private Terrace with Garden Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   },
//   {
//     id: 4,
//     name: "Sweet Room",
//     image: room4,
//     gallery: [sweet1, sweet2, sweet3, sweet4, sweet5, tabel],
//     price: "3000/Night",
//     total: 5,
//     description: "Indulge in our spacious Sweet Rooms, perfect for families. Enjoy separate living and sleeping areas, two bathrooms, air conditioning, and a full kitchen. Luxurious amenities ensure a relaxing stay for all.",
//     characteristics: [
//       { icon: "👨‍👩‍👧‍👦", label: "Perfect for Families" },
//       { icon: "📶", label: "Free High-Speed Wifi" },
//       { icon: "🍽️", label: "Complimentary Breakfast" },
//       { icon: "🛋️", label: "Separate Cozy Salon" },
//       { icon: "🛌", label: "Private Bedroom Retreat" },
//       { icon: "🚽", label: "Two Elegant Bathrooms" },
//       { icon: "❄️", label: "Air Conditioning" },
//       { icon: "🍳", label: "Fully Equipped Kitchen" },
//       { icon: "🛁", label: "Luxury Towels" },
//       { icon: "📺", label: "Smart TV with Streaming" },
//       { icon: "🪑", label: "Dining Area with Table and Chairs" },
//       { icon: "🏞️", label: "Private Patio with Scenic Views" },
//     ],
//     policies: {
//       checkIn: "Flexible Check-in",
//       checkOut: "Check-out by 11 AM",
//       pets: "Pets are not allowed.",
//     },
//   }
  
// ];

// const RoomDetail = () => {
//   const { id } = useParams();
//   const location = useLocation();
//   const { checkInDate: initialCheckInDate, checkOutDate: initialCheckOutDate, children: initialChildren, adults: initialAdults } = location.state || {};

//   const room = roomDetails.find(room => room.id === parseInt(id));

//   const [checkInDate, setCheckInDate] = useState(initialCheckInDate || '');
//   const [checkOutDate, setCheckOutDate] = useState(initialCheckOutDate || '');
//   const [adults, setAdults] = useState(initialAdults || 1);
//   const [children, setChildren] = useState(initialChildren || 0);
//   const [availabilityChecked, setAvailabilityChecked] = useState(false);
//   const [isRoomAvailable, setIsRoomAvailable] = useState(false);
//   const [isOpen, setIsOpen] = useState(false);
//   const [photoIndex, setPhotoIndex] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

//   useEffect(() => {
//     if (initialCheckInDate && initialCheckOutDate) {
//       handleCheckAvailability();
//     }
//   }, []);

//   const handleCheckAvailability = async () => {
//     if (!checkInDate || !checkOutDate) {
//       alert('Please select both check-in and check-out dates.');
//       return;
//     }

//     try {
//       const checkInTimestamp = new Date(checkInDate);
//       const checkOutTimestamp = new Date(checkOutDate);

//       // Fetch booking and reservation data
//       const bookingQuery = query(
//         collection(db, 'bookings'),
//         where('checkIn', '<=', checkOutTimestamp),
//         where('checkOut', '>=', checkInTimestamp),
//         where('rooms.type', '==', room.name)
//       );
//       const reservationQuery = query(
//         collection(db, 'reservations'),
//         where('checkIn', '<=', checkOutTimestamp),
//         where('checkOut', '>=', checkInTimestamp),
//         where('rooms.type', '==', room.name)
//       );

//       const [bookingSnapshot, reservationSnapshot] = await Promise.all([
//         getDocs(bookingQuery),
//         getDocs(reservationQuery)
//       ]);

//       const bookings = bookingSnapshot.docs.map(doc => doc.data());
//       const reservations = reservationSnapshot.docs.map(doc => doc.data());

//       // Calculate total bookings and reservations
//       const totalBooked = bookings.reduce((acc, booking) => {
//         return acc + booking.rooms.reduce((sum, room) => room.type === room.name ? sum + room.quantity : sum, 0);
//       }, 0);

//       const totalReserved = reservations.reduce((acc, reservation) => {
//         return acc + reservation.rooms.reduce((sum, room) => room.type === room.name ? sum + room.quantity : sum, 0);
//       }, 0);

//       const totalAvailableRooms = roomDetails.find(r => r.name === room.name).total - totalBooked - totalReserved;

//       setAvailabilityChecked(true);
//       setIsRoomAvailable(totalAvailableRooms > 0);

//     } catch (error) {
//       console.error("Error checking room availability: ", error);
//     }
//   };

//   const handleReservationSuccess = () => {
//     setIsSuccessDialogOpen(true);
//     setIsModalOpen(false);
//   };

//   if (!room) {
//     return <div>Room not found</div>;
//   }

//   return (
//     <div className="bg-white min-h-screen py-4">
//       <header className="bg-white">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
//           <h1 className="text-3xl text-custom-blue font-times">{room.name}</h1>
//         </div>
//       </header>
//       <section className="py-8">
//         <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//           <div className="bg-white shadow-md overflow-hidden mb-8 relative">
//             <img src={room.image} alt={room.name} className="w-full h-96 object-cover" />
//             <div className="absolute inset-0"></div> {/* Blue overlay */}
//           </div>

//           <section className="mb-8">
//             <div className="flex overflow-x-auto space-x-4">
//               {room.gallery.map((image, index) => (
//                 <img
//                   key={index}
//                   src={image}
//                   alt={`Gallery ${index + 1}`}
//                   className="w-64 h-64 object-cover shadow-md cursor-pointer"
//                   onClick={() => {
//                     setPhotoIndex(index);
//                     setIsOpen(true);
//                   }}
//                 />
//               ))}
//             </div>
//             {isOpen && (
//               <Lightbox
//                 mainSrc={room.gallery[photoIndex]}
//                 nextSrc={room.gallery[(photoIndex + 1) % room.gallery.length]}
//                 prevSrc={room.gallery[(photoIndex + room.gallery.length - 1) % room.gallery.length]}
//                 onCloseRequest={() => setIsOpen(false)}
//                 onMovePrevRequest={() =>
//                   setPhotoIndex((photoIndex + room.gallery.length - 1) % room.gallery.length)
//                 }
//                 onMoveNextRequest={() =>
//                   setPhotoIndex((photoIndex + 1) % room.gallery.length)
//                 }
//               />
//             )}
//           </section>

//           <div className="bg-white overflow-hidden mb-8">
//             <div className="p-8">
//               <p className="text-custom-blue font-times mb-6 text-lg">{room.description}</p>
//               <div className="mb-4">
//                 <h3 className="text-2xl md:text-2xl text-custom-blue font-times mb-2">Amenities:</h3>
//                 <div className="grid grid-cols-1 text-custom-blue md:grid-cols-2 lg:grid-cols-3 gap-2">
//                   {room.characteristics.map((characteristic, idx) => (
//                     <div key={idx} className="flex items-center font-times space-x-2 mb-2">
//                       <span role="img" aria-label="characteristic-icon">{characteristic.icon}</span>
//                       <span>{characteristic.label}</span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//               <div className="text-right">
//                 <p className="text-xl font-times font-bold text-custom-blue">Price: {room.price}</p>
//               </div>

//               <div className="mt-6 p-4 bg-blue-50 rounded-lg shadow-md">
//                 <h3 className="text-2xl md:text-2xl font-times mb-4 text-custom-blue">Rules</h3>
//                 <ul className="list-none space-y-3 text-gray-900 font-times text-lg">
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">🕓</span>
//                     <span className='text-custom-blue'>{room.policies.checkIn}</span>
//                   </li>
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">🚪</span>
//                     <span className='text-custom-blue'>{room.policies.checkOut}</span>
//                   </li>
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">💵</span>
//                     <span className='text-custom-blue'>
//                       Pay at the property -{" "}
//                       <span className="text-sm text-red-600">
//                         Payment should be made within 24 hours after reservation, otherwise the reservation will be canceled.
//                       </span>
//                     </span>
//                   </li>
//                   <li className="flex items-center">
//                     <span className="mr-2 text-blue-500">🐾</span>
//                     <span className='text-custom-blue'>{room.policies.pets}</span>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="mb-8">
//             <RoomCalendar roomId={room.id} /> {/* Add the RoomCalendar component here */}
//           </div>

//           <div className="text-right mt-4">
//             {!availabilityChecked ? (
//               <div className="flex flex-col space-y-2">
//                 <div className="flex flex-col space-y-2">
//                   <label className="font-times text-gray-700">Check-in Date:</label>
//                   <input
//                     type="date"
//                     value={checkInDate}
//                     onChange={(e) => setCheckInDate(e.target.value)}
//                     className="border-2 border-blue-500 p-2 rounded-lg"
//                     min={new Date().toISOString().split('T')[0]}
//                   />
//                 </div>
//                 <div className="flex flex-col space-y-2">
//                   <label className="font-times text-gray-700">Check-out Date:</label>
//                   <input
//                     type="date"
//                     value={checkOutDate}
//                     onChange={(e) => setCheckOutDate(e.target.value)}
//                     className="border-2 border-blue-500 p-2 rounded-lg"
//                     min={checkInDate ? checkInDate : new Date().toISOString().split('T')[0]}
//                   />
//                 </div>
//                 <div className="flex flex-col space-y-2">
//                   <label className="font-times text-gray-700">Adults:</label>
//                   <input
//                     type="number"
//                     min="1"
//                     value={adults}
//                     onChange={(e) => setAdults(Number(e.target.value))}
//                     className="border-2 border-blue-500 p-2 rounded-lg"
//                   />
//                 </div>
//                 <div className="flex flex-col space-y-2">
//                   <label className="font-times text-gray-700">Children:</label>
//                   <input
//                     type="number"
//                     min="0"
//                     value={children}
//                     onChange={(e) => setChildren(Number(e.target.value))}
//                     className="border-2 border-blue-500 p-2 rounded-lg"
//                   />
//                 </div>
//                 <button
//                   className="bg-custom-blue text-white font-times py-2 px-4 rounded-full text-lg hover:bg-blue-600 focus:outline-none mt-4"
//                   onClick={handleCheckAvailability}
//                 >
//                   Check Availability
//                 </button>
//               </div>
//             ) : isRoomAvailable ? (
//               <button
//                 className="bg-custom-blue text-white font-times py-2 px-4 rounded-full text-lg hover:bg-blue-600 focus:outline-none mt-4"
//                 onClick={() => setIsModalOpen(true)}
//               >
//                 Make Reservation
//               </button>
//             ) : (
//               <p className="text-red-500 font-times mt-4">No available rooms found for the selected dates.</p>
//             )}
//           </div>

//           {isModalOpen && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//               <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full h-full overflow-y-auto">
//                 <div className="max-w-lg mx-auto" onClick={(e) => e.stopPropagation()}>
//                   <ReservationForm
//                     room={room}
//                     onSuccess={handleReservationSuccess}
//                     onClose={() => setIsModalOpen(false)}
//                     checkInDate={checkInDate}
//                     checkOutDate={checkOutDate}
//                     children={children}
//                     adults={adults}
//                   />
//                 </div>
//               </div>
//             </div>
//           )}

//           {isSuccessDialogOpen && (
//             <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//               <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
//                 <h2 className="text-2xl font-times mb-4 text-green-500">Reservation Successful</h2>
//                 <p className="mb-4 font-times text-sm">You have successfully reserved your room.</p>
//                 <p className="mb-4 font-times text-sm">Our team will contact you soon. Thank You</p>
//                 <p className="mb-4 font-times text-sm text-red-600">Note: Reservation will be canceled after 4 hours if payment is not completed. Make payment at the property.</p>
//                 <button
//                   className="w-full bg-custom-blue text-white py-2 font-times rounded-custom hover:bg-blue-600 transition duration-200"
//                   onClick={() => setIsSuccessDialogOpen(false)}
//                 >
//                   OK
//                 </button>
//               </div>
//             </div>
//           )}
//         </div>
//       </section>
//     </div>
//   );
// }

// export default RoomDetail;
