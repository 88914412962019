import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import room1 from '../assets/semi1.jpg';
import room2 from '../assets/twin3.jpg';
import room3 from '../assets/twin4.jpg';
import room4 from '../assets/twin1.jpg';
import room5 from '../assets/sweet3.jpg';
import room6 from '../assets/sweet4.jpg';
import room7 from '../assets/semi2.jpg';
import room8 from '../assets/semi4.jpg';
import room9 from '../assets/semi5.jpg';

import hotelView1 from '../assets/Home.jpg';
import hotelView2 from '../assets/outdoor.jpg';

import dining1 from '../assets/food1.jpg';
import dining2 from '../assets/burger.jpg';
import dining3 from '../assets/food3.jpg';
import dining4 from '../assets/food10.jpg';
import dining5 from '../assets/food5.jpg';
import dining6 from '../assets/food6.jpg';
import dining7 from '../assets/food7.jpg';
import dining8 from '../assets/food8.jpg';
import dining9 from '../assets/food11.jpg';

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [currentSection, setCurrentSection] = useState([]);
  const [activeSection, setActiveSection] = useState('rooms');

  const sections = {
    rooms: [room1, room2, room3, room4, room5, room6, room7, room8, room9],
    hotelViews: [hotelView1, hotelView2],
    dining: [dining1, dining2, dining3, dining4, dining5, dining6, dining7, dining8, dining9],
  };

  const openLightbox = (section, index) => {
    setCurrentSection(sections[section]);
    setCurrentImage(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const moveNext = () => {
    setCurrentImage((currentImage + 1) % currentSection.length);
  };

  const movePrev = () => {
    setCurrentImage((currentImage + currentSection.length - 1) % currentSection.length);
  };

  return (
    <div className="bg-white min-h-screen">
      <nav className="bg-white py-4 sticky top-0 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-center space-x-6">
          <a
            href="#rooms"
            className={`text-custom-blue font-medium hover:underline font-times ${activeSection === 'rooms' ? 'underline' : ''}`}
            onClick={() => setActiveSection('rooms')}
          >
            Rooms
          </a>
          <a
            href="#hotelView"
            className={`text-custom-blue font-medium hover:underline font-times ${activeSection === 'hotelView' ? 'underline' : ''}`}
            onClick={() => setActiveSection('hotelView')}
          >
            Hotel View
          </a>
          <a
            href="#dining"
            className={`text-custom-blue font-medium hover:underline font-times ${activeSection === 'dining' ? 'underline' : ''}`}
            onClick={() => setActiveSection('dining')}
          >
            Dining
          </a>
        </div>
      </nav>

      <section id="rooms" className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl mb-8 text-center font-times">Rooms</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
            {sections.rooms.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Room ${index + 1}`}
                className={`w-full object-cover cursor-pointer ${index === 3 ? 'md:col-span-2 md:row-span-2 h-400' : 'h-200'}`}
                onClick={() => openLightbox('rooms', index)}
              />
            ))}
          </div>
        </div>
      </section>

      <section id="dining" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-times text-center mb-8">Dining</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
            {sections.dining.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Dining ${index + 1}`}
                className={`w-full object-cover cursor-pointer ${index === 3 ? 'md:col-span-2 md:row-span-2 h-400' : 'h-200'}`}
                onClick={() => openLightbox('dining', index)}
              />
            ))}
          </div>
        </div>
      </section>

      <section id="hotelView" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl font-times text-center mb-8">Hotel View</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
            {sections.hotelViews.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Hotel View ${index + 1}`}
                className={`w-full object-cover cursor-pointer ${index === 3 ? 'md:col-span-2 md:row-span-2 h-400' : 'h-200'}`}
                onClick={() => openLightbox('hotelViews', index)}
              />
            ))}
          </div>
        </div>
      </section>

      {isOpen && (
        <Lightbox
          mainSrc={currentSection[currentImage]}
          nextSrc={currentSection[(currentImage + 1) % currentSection.length]}
          prevSrc={currentSection[(currentImage + currentSection.length - 1) % currentSection.length]}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={movePrev}
          onMoveNextRequest={moveNext}
        />
      )}
    </div>
  );
};

export default Gallery;
