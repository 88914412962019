import React, { useState, useEffect } from 'react';
import { collection, addDoc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { db } from './firebase';

const ReservationForm = ({ room, onSuccess, onClose, checkInDate, checkOutDate, children, adults }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [localCheckInDate, setLocalCheckInDate] = useState(checkInDate || '');
  const [localCheckOutDate, setLocalCheckOutDate] = useState(checkOutDate || '');
  const [localAdults, setLocalAdults] = useState(adults || 1);
  const [localChildren, setLocalChildren] = useState(children || 0);

  useEffect(() => {
    if (localCheckInDate && new Date(localCheckInDate) > new Date(localCheckOutDate)) {
      setLocalCheckOutDate(localCheckInDate);
    }
  }, [localCheckInDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Convert date strings to Firestore Timestamp objects
      const checkInTimestamp = Timestamp.fromDate(new Date(localCheckInDate));
      const checkOutTimestamp = Timestamp.fromDate(new Date(localCheckOutDate));

      await addDoc(collection(db, 'reservations'), {
        name,
        email,
        phone,
        roomType: room.name,
        adults: localAdults,
        children: localChildren,
        checkIn: checkInTimestamp,
        checkOut: checkOutTimestamp,
        timestamp: serverTimestamp(),
      });

      onSuccess();
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleCancel = () => {
    setName('');
    setEmail('');
    setPhone('');
    setLocalCheckInDate('');
    setLocalCheckOutDate('');
    setLocalAdults(1);
    setLocalChildren(0);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-4 md:p-8 bg-white rounded shadow-md">
      <h2 className="text-xl md:text-3xl mb-4 md:mb-6 font-times text-custom-blue text-center">
        Reserve {room.name}
      </h2>
      {checkInDate && checkOutDate && adults !== null && children !== null ? (
        <div className="bg-custom-blue text-white p-4 rounded-lg mb-4">
          <h2 className="text-xl md:text-4xl mb-4 md:mb-6 font-times text-white text-center">Search Result</h2>
          <p className="font-times text-lg">Check-In Date: {checkInDate}</p>
          <p className="font-times text-lg">Check-Out Date: {checkOutDate}</p>
          <p className="font-times text-lg">Adults: {adults}</p>
          <p className="font-times text-lg">Children: {children}</p>
        </div>
      ) : (
        <div className="mb-4">
          <div className="mb-4">
            <label className="block text-custom-blue font-times mb-2" htmlFor="checkInDate">Check-In Date</label>
            <input
              type="date"
              id="checkInDate"
              value={localCheckInDate}
              onChange={(e) => setLocalCheckInDate(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              min={new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className="mb-4">
            <label className="block text-custom-blue font-times mb-2" htmlFor="checkOutDate">Check-Out Date</label>
            <input
              type="date"
              id="checkOutDate"
              value={localCheckOutDate}
              onChange={(e) => setLocalCheckOutDate(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              min={localCheckInDate || new Date().toISOString().split('T')[0]}
            />
          </div>
          <div className="mb-4">
            <label className="block text-custom-blue font-times mb-2" htmlFor="adults">Number of Adults</label>
            <input
              type="number"
              id="adults"
              value={localAdults}
              onChange={(e) => setLocalAdults(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              min="1"
            />
          </div>
          <div className="mb-4">
            <label className="block text-custom-blue font-times mb-2" htmlFor="children">Number of Children</label>
            <input
              type="number"
              id="children"
              value={localChildren}
              onChange={(e) => setLocalChildren(e.target.value)}
              className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
              min="0"
            />
          </div>
        </div>
      )}

      <div className="mb-4">
        <label className="block text-custom-blue font-times mb-2" htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-custom-blue font-times mb-2" htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-custom-blue font-times mb-2" htmlFor="phone">Phone</label>
        <input
          type="tel"
          id="phone"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          className="w-full px-3 py-2 border rounded-lg font-times focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>

      <p className="text-red-600 font-times text-sm mb-4">Note: Reservation will be canceled after 4 hours if payment is not completed.</p>
      <p className="text-custom-blue font-bold font-times mb-4">Pay at the property!!</p>
      <p className="text-custom-blue font-times mb-4">Contact our team for more information: <a href="tel:+251254114312" className="text-blue-600 hover:underline">0254114312</a></p>

      <div className="flex flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-4 justify-end">
        <button
          type="button"
          onClick={handleCancel}
          className="text-red-600 hover:text-red-700 font-times text-sm focus:outline-none"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-full md:w-auto bg-custom-blue text-white py-2 font-times rounded-full hover:bg-blue-600 transition duration-200"
        >
          Reserve
        </button>
      </div>
    </form>
  );
};

export default ReservationForm;
