import React, { useState } from 'react';

// Sample FAQ data
const faqs = [
  {
    question: "What is the check-in time?",
    answer: "Check-in time is available anytime if rooms are available.",
  },
  {
    question: "What is the check-out time?",
    answer: "Check-out time is by 11:00 AM.",
  },
  {
    question: "Do you offer room service?",
    answer: "Yes, room service is available 24/7.",
  },

  {
    question: "Where is the hotel located?",
    answer: "The hotel is located in Biftu Mall, Dire Dawa.",
  },
  {
    question: "How is the payment handled?",
    answer: "Payment is made at the property within 24 hours of reservation.",
  },
  // Add more FAQs as needed
];

const FAQPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedQuestionIndex, setExpandedQuestionIndex] = useState(null);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (index) => {
    setExpandedQuestionIndex(expandedQuestionIndex === index ? null : index);
  };

  const filteredFaqs = faqs.filter(faq =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8">
      <h1 className="text-3xl text-custom-blue font-times font-bold text-center mb-6">Frequently Asked Questions</h1>
      <div className="max-w-lg mx-auto mb-6">
        <input
          type="text"
          placeholder="Search FAQs..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full px-4 py-2 border font-times border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="space-y-4">
        {filteredFaqs.map((faq, index) => (
          <div key={index} className="border font-times rounded-md shadow-sm">
            <button
              onClick={() => handleToggle(index)}
              className="w-full px-4 py-2 text-left font-times bg-gray-100 border-b rounded-t-md hover:bg-gray-200 focus:outline-none"
            >
              <h2 className="text-lg font-times font-semibold">{faq.question}</h2>
            </button>
            {expandedQuestionIndex === index && (
              <div className="px-4 py-2 bg-white font-times rounded-b-md">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
