import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import restaurantImage from '../assets/res.jpg'; 
import special from '../assets/combo1.jpg';
import pizza from '../assets/pizza.jpg';
import burger from '../assets/burger.jpg';
import chicken from '../assets/chicken.jpg';
import club from '../assets/club.jpg';
import openair from '../assets/outdoor.jpg'; 
import backgroundImage from '../assets/res.jpg';
 
import roomdining from '../assets/sweet.jpg';
import cafeImage from '../assets/indoor2.jpg';
import guestImage1 from '../assets/guest.jpg'; 
import guestImage2 from '../assets/guest.jpg';
import guestImage3 from '../assets/guest.jpg';

const Restaurant = () => {
  const testimonials = [
    {
      quote: "Dining at Caravan Dire Hotel's restaurant was an incredible experience. The food was delicious, and the variety of dishes was impressive. The rooms were spacious, clean, and very comfortable. I highly recommend this hotel to anyone visiting Dire Dawa.",
      name: "John Smith",
      image: guestImage1,
    },
    {
      quote: "The restaurant at Caravan Dire Hotel served some of the best meals I've ever had. The ingredients were fresh, and the flavors were amazing. The rooms were spotless and well-maintained, ensuring a very comfortable stay. I will definitely return here in the future.",
      name: "Emily Johnson",
      image: guestImage2,
    },
    {
      quote: "Caravan Dire Hotel's restaurant offers fantastic food with great service. The staff went above and beyond to make sure we had everything we needed. The rooms were clean, spacious, and very relaxing. Highly recommended!",
      name: "Michael Brown",
      image: guestImage3,
    },
  ];
  
  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const { ref: heroRef, inView: heroInView } = useInView({ triggerOnce: true });
  
  const { ref: roomRef, inView: roomInView } = useInView({ triggerOnce: true });
  const { ref: popularRef, inView: popularInView } = useInView({ triggerOnce: true });
  const { ref: testimonialRef, inView: testimonialInView } = useInView({ triggerOnce: true });

  return (
    <div className="bg-white min-h-screen">
      {/* Hero Section */}
      <motion.div
        className="relative bg-white shadow-md overflow-hidden mb-8 w-screen"
        ref={heroRef}
        initial={{ opacity: 0, scale: 1.1 }}
        animate={{ opacity: heroInView ? 1 : 0, scale: heroInView ? 1 : 1.1 }}
        transition={{ duration: 1 }}
      >
    <div className="relative w-full h-128">
  <img
    src={restaurantImage}
    alt="Restaurant"
    className="w-full h-full object-cover transition-transform duration-500 ease-in-out transform hover:scale-105"
  />
  <div className="absolute inset-0 bg-blue-500 opacity-30 pointer-events-none"></div> {/* Blue overlay */}
  <div className="absolute inset-0 flex items-center justify-center text-white text-3xl font-bold">
    <p className="text-center font-times">Restaurant</p>
  </div> {/* Text overlay */}
</div>

      </motion.div>

  
      <section>
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex items-center">
          <div>
            <h2 className="text-2xl font-times transition-transform duration-300 transform  text-custom-blue hover:scale-105">Dining</h2>
            <p className="text-gray-600 font-times mt-4 transition-opacity duration-300 hover:opacity-75">
  Discover delightful dining at Caravan Dire Hotel in Dire Dawa. Enjoy a variety of dishes, from local favorites to international cuisines, all made with fresh, seasonal ingredients. Our welcoming atmosphere and friendly staff ensure a memorable dining experience. Join us for breakfast, lunch, or dinner and savor the best of our menu.
</p>

          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4">
  <h2 className="text-2xl font-times pt-8 pb-4 text-custom-blue mb-2">Dining Areas</h2>
  <div className="bg-white overflow-hidden max-w-7xl px-4 mb-8 flex flex-wrap -mx-4 pt-8">
  
  <motion.div
  className="relative w-full md:w-1/3 px-4 mb-8"
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.8 }}
>
  <div className="relative w-full h-64">
    <motion.img
      src={openair}
      alt="Open Air Dining"
      className="w-full h-full object-cover border-b-4 border-custom-blue transition-transform duration-300 transform hover:scale-105"
      placeholder="blur"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    />
    <div className="absolute inset-0 bg-blue-500 opacity-30"></div> {/* Blue opacity overlay */}
    <div className="absolute inset-0 flex items-center justify-center text-center text-white p-4">
      <h3 className="text-3xl  font-bold font-times">Open Air Dining</h3>
    </div>
  </div>
  <div className="p-8">
    <p className="text-gray-600 font-times mb-4">
      Experience the beauty of nature while enjoying your meal in our open-air dining area at Caravan Dire Hotel in Dire Dawa. Our serene outdoor setting enhances your dining experience, providing a relaxing atmosphere to savor delicious dishes and beverages crafted with care by our chefs.
    </p>
  </div>
</motion.div>

<motion.div
  className="relative w-full md:w-1/3 px-4 mb-8"
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.8 }}
>
  <div className="relative w-full h-64">
    <motion.img
      src={restaurantImage}
      alt="Indoor Dining"
      className="w-full h-full object-cover border-b-4 border-custom-blue transition-transform duration-300 transform hover:scale-105"
      placeholder="blur" // Lazy load image
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    />
    <div className="absolute inset-0 bg-blue-500 opacity-30"></div> {/* Blue opacity overlay */}
    <div className="absolute inset-0 flex items-center justify-center text-center text-white p-4">
      <h3 className="text-3xl font-bold font-times">Indoor Dining</h3>
    </div>
  </div>
  <div className="p-8">
    <p className="text-gray-600 font-times mb-4">
      Experience elegant indoor dining at Caravan Dire Hotel in Dire Dawa. Our ambiance creates the perfect setting to enjoy a variety of delicious meals, all crafted from fresh, seasonal ingredients by our talented chefs.
    </p>
  </div>
</motion.div>

<motion.div
  className="relative w-full md:w-1/3 px-4 mb-8"
  initial={{ opacity: 0, y: 50 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.8 }}
>
  <div className="relative w-full h-64">
    <motion.img
      src={cafeImage}
      alt="Cafe"
      className="w-full h-full object-cover border-b-4 border-custom-blue transition-transform duration-300 transform hover:scale-105"
      placeholder="blur"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    />
    <div className="absolute inset-0 bg-blue-400 opacity-30"></div> {/* Blue opacity overlay */}
    <div className="absolute inset-0 flex items-center justify-center text-center text-white p-4">
      <h3 className="text-3xl font-bold font-times">Our Cafe</h3>
    </div>
  </div>
  <div className="p-8">
    <p className="text-gray-600 font-times mb-4">
      Indulge in a variety of delicious coffee, tea, and cakes served any time of the day or night at the cafe in Caravan Dire Hotel, located in Dire Dawa. Whether you're starting your morning with a fresh brew or winding down in the evening with a sweet treat, our cafe offers a cozy ambiance and delightful options to satisfy your cravings.
    </p>
  </div>
</motion.div>

  
  </div>
</div>


<section className="pb-16 bg-white">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    <motion.div
      className="relative mt-8 bg-white shadow-md rounded-lg overflow-hidden flex flex-col md:flex-row transition-transform duration-300 hover:scale-105"
      ref={roomRef}
      initial={{ opacity: 0, y: 50 }} // Initial state
      animate={{ opacity: roomInView ? 1 : 0, y: roomInView ? 0 : 50 }} // Animated state
      transition={{ duration: 1 }} // Transition settings
    >
      <div className="relative md:w-1/2">
        <img
          src={roomdining}
          alt="In-Room Dining"
          className="w-full h-full object-cover transition-transform duration-500 ease-in-out transform hover:scale-105"
        />
        <div className="absolute inset-0 bg-blue-500 opacity-30"></div> {/* Blue opacity overlay */}
      </div>
      <div className="md:w-1/2 p-6 flex flex-col justify-center">
        <h2 className="text-3xl font-times text-custom-blue text-center mb-8">
          In-Room Dining
        </h2>
        <p className="text-gray-600 text-sm font-times mb-4">
          Enjoy delicious dishes and beverages right in your room at Caravan Dire Hotel, the top luxury spot in Dire Dawa. Our chefs use fresh ingredients to create delightful meals, from local favorites to international cuisine. Whether you're here for business or leisure, we offer the perfect blend of comfort and luxury.
        </p>
      </div>
    </motion.div>
  </div>
</section>


       
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            className="bg-white shadow-md rounded-lg overflow-hidden mb-8"
            ref={popularRef}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: popularInView ? 1 : 0, y: popularInView ? 0 : 50 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-times py-4 px-6 bg-custom-blue text-white rounded-t-lg">Popular Menu Items</h2>
            <div className="flex space-x-6 overflow-x-auto p-4" id="scroll-container">
              {[
                { src: special, alt: "Special Combo", title: "Special Combo",  },
                { src: chicken, alt: "Mandi", title: "Mandi",  },
                { src: pizza, alt: "Pizza", title: "Pizza",  },
                { src: burger, alt: "Burger", title: "Burger",  },
                { src: club, alt: "Club Sandwich", title: "Club Sandwich",  }
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white p-4 flex-shrink-0 w-72 rounded-lg shadow-md hover:shadow-lg transition duration-300 transform hover:scale-105"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: popularInView ? 1 : 0, scale: popularInView ? 1 : 0.9 }}
                  transition={{ duration: 0.5 }}
                >
                  <img src={item.src} alt={item.alt} className="w-full h-40 object-cover mb-4"/>
                  <h3 className="text-lg text-custom-blue font-times mb-2">{item.title}</h3>
                
                </motion.div>
              ))}
            </div>
            <div className="flex justify-center mt-6 mb-4">
              <Link to="/menu">
                <button className="bg-custom-blue text-white text-lg px-6 py-2 font-times rounded-full shadow-lg hover:bg-blue-900 transition duration-300">
                  View Full Menu
                </button>
              </Link>
            </div>
          </motion.div>
        </div>

        <section
  className="relative bg-cover bg-center py-16"
  style={{ backgroundImage: `url(${backgroundImage})` }}
>
  <div className="absolute inset-0 bg-blue-500 opacity-30"></div> {/* Blue opacity overlay */}
  <div className="relative z-10 max-w-7xl mx-auto px-4">
    <h2 className="text-3xl text-white font-times  font-bold text-center mb-8">What Our Clients Say</h2>
    <motion.div
      className="flex flex-col items-center"
      ref={testimonialRef}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: testimonialInView ? 1 : 0, y: testimonialInView ? 0 : 50 }}
      transition={{ duration: 1 }}
    >
      <div className="text-left mb-8">
        <p className="text-md text-white font-times mb-4">{testimonials[currentTestimonial].quote}</p>
      </div>
      <div className="flex items-center">
        <div className="relative">
          <img
            src={testimonials[currentTestimonial].image}
            alt={testimonials[currentTestimonial].name}
            className="w-16 h-16 rounded-full mb-4 mr-4 transition-transform duration-300 transform hover:scale-110"
          />
         
        </div>
        <div className="text-left">
          <div className="flex items-center">
            <div className="text-lg font-semibold text-white font-times">{testimonials[currentTestimonial].name}</div>
          </div>
          <div className="flex items-center mt-2">
            <span className="text-yellow-500">★★★★☆</span>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-2 mt-4">
        {testimonials.map((_, index) => (
          <button
            key={index}
            className={`h-3 w-3 rounded-full ${index === currentTestimonial ? 'bg-white' : 'bg-gray-400'} transition duration-300`}
            onClick={() => setCurrentTestimonial(index)}
          ></button>
        ))}
      </div>
    </motion.div>
  </div>
</section>


      </section>
    </div>
  );
};

export default Restaurant;
