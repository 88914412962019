import React from 'react';
import hotel from '../assets/Home.jpg';
import dining from '../assets/dining.jpg';
import room from '../assets/room1.jpg';
import meet from '../assets/m1.jpg';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
              <img src={hotel} alt="Hotel Exterior" className="w-full rounded-lg shadow-md" />
            </div>
            <div className="md:col-span-1">
              <h2 className="text-3xl font-serif text-custom-blue mb-4">Welcome to Caravan Dire Hotel</h2>
              <p className="text-gray-600 font-serif leading-relaxed">
                Discover the epitome of luxury and comfort at Caravan Dire Hotel, ideally located in the heart of Dire Dawa, Ethiopia. Our commitment to exceptional service and exquisite accommodations ensures an unforgettable experience for every guest.
              </p>
              <p className="text-gray-600 font-serif leading-relaxed">
                For reservations or inquiries, please contact us at <a href="tel:+251254114312" className="text-custom-blue hover:underline">+251 25 411 4312</a>.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
              <h3 className="text-2xl font-serif text-custom-blue mb-4">Our Vision</h3>
              <p className="text-gray-600 font-serif leading-relaxed">
                At Caravan Dire Hotel, our vision is to establish new standards in luxury hospitality and personalized service within Dire Dawa. We aspire to create memorable experiences that exceed our guests' expectations at every turn.
              </p>
            </div>
            <div className="md:col-span-1">
              <h3 className="text-2xl font-serif text-custom-blue mb-4">Our Mission</h3>
              <p className="text-gray-600 font-serif leading-relaxed">
                Our mission is to provide unparalleled hospitality, ensuring every guest feels valued and cherished. We are dedicated to delivering exceptional service, luxurious accommodations, and remarkable dining experiences that reflect our commitment to excellence.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
             
            </div>
            <div className="md:col-span-1">
              <h3 className="text-2xl font-serif text-custom-blue mb-4">Our Dining Experience</h3>
              <p className="text-gray-600 font-serif leading-relaxed">
                Embark on a culinary journey at Caravan Dire Hotel. Our restaurant offers a diverse menu of local and international cuisines, ensuring an exquisite dining experience whether you choose to dine indoors or enjoy the scenic outdoor spaces.
              </p>
            </div>
          </div>
        </section>

        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
              <h3 className="text-2xl font-serif text-custom-blue mb-4">Our Accommodations</h3>
              <p className="text-gray-600 font-serif leading-relaxed">
                Experience unparalleled comfort and elegance in our spacious rooms and suites. Each accommodation is designed for relaxation and rejuvenation, equipped with modern amenities to ensure a serene and memorable stay.
              </p>
            </div>
            <div className="md:col-span-1">
              <img
                src={room}
                alt="Hotel Room"
                className="w-3/4 rounded-lg shadow-md"
              />
            </div>
          </div>
        </section>

        <section className="mb-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="md:col-span-1">
              <img
                src={meet}
                alt="Meeting Hall"
                className="w-full rounded-lg shadow-md"
              />
            </div>
            <div className="md:col-span-1">
              <h3 className="text-2xl font-serif text-custom-blue mb-4">Meeting Hall for 361 People</h3>
              <p className="text-gray-600 font-serif leading-relaxed">
                Host your events and conferences in our expansive meeting hall, which can accommodate up to 361 people. Our versatile space and dedicated service ensure the success of your corporate seminars, meetings, or special occasions.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div className="text-center">
            <Link to="/" className="bg-custom-blue text-white text-lg px-6 py-3 font-serif rounded-full shadow-lg hover:bg-blue-700 transition duration-300">
              Explore Our Hotel
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUs;