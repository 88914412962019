import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchBookedDates } from '../utils/book'; // Adjust the path accordingly

const RoomCalendar = ({ roomId, roomType }) => {
  const [bookedDates, setBookedDates] = useState({});
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const fetchBooked = async () => {
      const dates = await fetchBookedDates(roomId);
      setBookedDates(dates);
    };
    fetchBooked();
  }, [roomId]);

  const tileClassName = ({ date, view }) => {
    if (view === 'month') { // Only apply to month view
      const dateString = date.toISOString().split('T')[0];
      if (bookedDates[dateString] && bookedDates[dateString][roomType] === 0) {
        return 'booked'; // Custom class for fully booked dates
      }
    }
    return null;
  };

  return (
    <div className="calendar-container p-6 max-w-md mx-auto bg-white shadow-md rounded-lg border border-gray-200">
      <h2 className="text-xl font-semibold font-times mb-4 text-custom-blue">Room Availability</h2>
      <Calendar
        tileClassName={tileClassName}
        onChange={setValue}
        value={value}
        minDate={new Date()} // Ensures the calendar only shows dates from today onward
        className="custom-calendar border border-gray-300 rounded-lg"
      />
      <div className="mt-4 flex items-center">
        <div className="w-4 h-4 bg-custom-blue mr-2"></div>
        <span className="font-times text-custom-blue">- Booked</span>
      </div>
    
    </div>
  );
};

export default RoomCalendar;
