import React from 'react';
import { motion } from 'framer-motion';
import logo from '../assets/logo.jpg'; // Make sure to replace this with the correct path to your logo image

const footerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } }
};

const linkHover = { scale: 1.1, transition: { duration: 0.3 } };

const Footer = () => {
  return (
    <footer className="bg-custom-blue text-white py-8">
      <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-7xl">
        <motion.div
          className="grid grid-cols-1 md:grid-cols-4 gap-8"
          initial="hidden"
          whileInView="visible"
          variants={footerVariants}
        >
          <motion.div className="mb-8 md:mb-0 flex flex-col items-center" whileHover={linkHover}>
            <img src={logo} alt="Caravan Dire Hotel Logo" className="w-72 h-64 mb-4 rounded-lg" />
          </motion.div>
          <motion.div className="mb-8 md:mb-0 flex flex-col md:flex-row justify-between" whileHover={linkHover}>
            <div className="flex-1 mb-4 md:mb-0">
              <h2 className="text-2xl font-times">Resources</h2>
              <ul className="mt-4 space-y-2 text-gray-200">
                <li>
                  <a href="/services" className="hover:text-white font-times">
                    Services
                  </a>
                </li>
                <li>
                  <a href="/menu" className="hover:text-white font-times">
                    Menu
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-1">
              <h2 className="text-2xl font-times">Contact Us</h2>
              <ul className="mt-4 space-y-2 text-gray-200">
                <li>
                  <a href="/contact" className="hover:text-white font-times">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="/faq" className="hover:text-white font-times">
                    FAQ
                  </a>
                </li>
              </ul>
            </div>
          </motion.div>
        </motion.div>
        <motion.div
  className="w-full  border-gray-700 pt-4 flex flex-col items-center bg-white text-custom-blue"
  initial="hidden"
  whileInView="visible"
  variants={footerVariants}
  style={{ paddingLeft: '0', paddingRight: '0' }} // Remove left and right padding
>
  <motion.p className="text-center font-times mb-2" whileHover={linkHover}>
    All rights reserved @caravan Dire hotel 2024
  </motion.p>
  <motion.p className="text-center font-times mb-2" whileHover={linkHover}>
    Developed by : 
    <a 
      href="https://nexgensoftwaresolutions.github.io/Nexgen.github.io/" 
      className="hover:text-custom-blue font-bold"
      target="_blank"
      rel="noopener noreferrer"
    >
      NexGen Software Solutions
    </a>
  </motion.p>
</motion.div>

      </div>
    </footer>
  );
};

export default Footer;