import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../components/firebase'; // Ensure this imports the Firestore instance correctly

export const fetchBookedDates = async (roomId) => {
  const bookingsRef = collection(db, 'bookings');
  const q = query(bookingsRef, where('roomId', '==', roomId));
  const querySnapshot = await getDocs(q);

  const bookedDates = {};

  querySnapshot.forEach((doc) => {
    const data = doc.data();
    const startDate = new Date(data.checkInDate.seconds * 1000);
    const endDate = new Date(data.checkOutDate.seconds * 1000);

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const dateString = date.toISOString().split('T')[0];

      if (!bookedDates[dateString]) {
        bookedDates[dateString] = {
          Standard: 0,
          TwinBed: 0,
          SemiSweet: 0,
          Sweet: 0,
        };
      }

      data.rooms.forEach((room) => {
        bookedDates[dateString][room.type] += room.quantity;
      });
    }
  });

  return bookedDates;
};