import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

// import breakfastImage from '../assets/break.jpg';
// import lunchImage from '../assets/combo1.jpg';
// import burgerImage from '../assets/burger.jpg';
// import sandwichImage from '../assets/club.jpg';
// import ethiopianImage from '../assets/eth.jpg';
// import pizzaImage from '../assets/food6.jpg';
// import juiceImage from '../assets/juice.jpg';
// import cakeImage from '../assets/cake.jpg';
// import hotDrinksImage from '../assets/hot.jpg';

const menuData = {
  BreakFast: [
    { name: 'Scrambled Eggs',  price: 200 },
    { name: 'Omelet',  price: 200 },
    { name: 'Boiled Eggs',price: 150 },
    { name: 'Melewaa',  price: 170 },
    { name: 'Fetira',  price: 250 },
    { name: 'Chechebsa with Oil', price: 200 },
    { name: 'Chechebsa with Butter',  price: 250 },
    { name: 'Fasting Firfir', price: 210 },
    { name: 'Kinche', price: 150 },
    { name: 'Bulla with Butter',  price: 180 },
    { name: 'Bulla with Oil', price: 170 },
    { name: 'Foul', price: 150 },
    { name: 'Special Foul',price: 180 },
    { name: 'Special Combo', price: 350 },
    { name: 'Dulet',  price: 200 },
  ],
  "Lunch and Dinner": [
    { name: 'Special caravan Combo',price: 1500 },
    { name: ' Special Goat',price: 450 },
    { name: 'Goat - Tibs',price: 400 },
    { name: 'Rice - Normal',price: 200 },
    { name: 'Rice with Meat',price: 280 },
    { name: 'Rice with Chicken',price: 300 },
    { name: 'Rice with Bolanise Sauce',price: 350},
    { name: 'Rice with Fish',price: 350 },
    { name: 'Spaghetti with Bolognese Sauce',price: 350 },
    { name: 'Spaghetti with Meat',price: 370 },
    { name: 'Spaghetti with Cheese',price: 400 },
    { name: 'Spaghetti with Vegetables',price: 250 },
    { name: 'Chicken Xibsi',price: 300},
    { name: 'Chicken Mondi',price: 600 },
    { name: 'Carnival Hanid / Meat Mendi',price: 600}
  ],
  Burger: [
    { name: 'Caravan Special Burger',price: 450 },
    { name: 'Special Burger',price: 400 },
    { name: 'Normal Burger',price: 280 },
    { name: 'Cheese Burger',price: 300 },
    { name: 'Chicken Burger',price: 400 },
    { name: 'Tuna Burger',price: 300 },
    { name: 'Triple Burger',price: 400 },
    { name: 'Double Burger',price: 450 }
  ],
  Sandwich: [
    { name: 'Egg Sandwich',price: 250 },
    { name: 'Club Sandwich',price: 300 },
    { name: 'Chicken Club Sandwich',price: 350 },
    { name: 'Beef Sandwich',price: 300 },
    { name: 'Atiklt Sandwich',price: 200 },
    { name: 'Tuna Sandwich',price: 230 },
    { name: 'Shewarma Special',price: 400 },
  ],
  "Ethiopian Food": [
    { name: 'ሽሮ',price: 200 },
    { name: 'ሽሮ በቱና',price: 320 },
    { name: 'ቦዘና ሽሮ',price: 380 },
    { name: 'ጥብስ ጭቅና',price: 350 },
    { name: 'ጥብስ የፍየል ',price: 400 },
    { name: 'አገልግል',price: 450 },
    { name: 'ዶሮ ወጥ ',price: 400 },
    { name: 'ጎመን - በስጋ',price: 300 },
    { name: 'ቅቅል',price: 350 },
    { name: 'ክትፎ',price: 500 },
    { name: 'እስፓድል - ክትፎ',price: 600 },
    { name: 'ደረቅ ጥብስ - እስፓድል',price: 500 },
    { name: 'ሞሰራ በስጋ',price: 400 },
    { name: 'አይብ',price: 350 },
    { name: 'አይብ በጎመን',price: 380 },
    { name: 'ሱፍ - ፍትፍት',price: 250 },
    { name: 'በየአይነት',price: 250 },
    { name: 'ጎመን ክትፎ',price: 250 },
  ],
  Pizza: [
    { name: 'caravan special', price: 450 },
    { name: 'Special Pizza',  price: 450 },
    { name:'Oriental Pizza', price: 450 },
    { name: 'Margarita Pizza', price: 300 },
    { name: 'Beef Pizza', price: 300 },
    { name: 'Nioia Pizza',  price: 400 },
    { name: 'Four-Season Pizza',price: 400 },
    { name: 'Tuna Pizza',  price: 400 },
    { name: 'At Kilt Pizza', price: 300 },
    { name: 'Italian Pizza',  price: 400 },
  ],
  Juice: [
    { name: 'Carnival - Special Juice',price: 200 },
    { name: 'Avocado Juice',price: 200 },
    { name: 'Avocado Milk Shake',price: 230 },
    { name: 'Papaya Juice',price: 200 },
    { name: 'Papaya Milk Shake',price: 200 },
    { name: 'Ambashock',price: 200 },
    { name: 'Orange Juice',price: 200 },
    { name: 'Watermelon Juice',price: 200 },
    { name: 'Orange, With Honey',price: 200 },
    { name: 'Watermelon With Honey',price: 200 },
    { name: 'Banana With Milk Shake',price: 200 },
    { name: 'Αyton',price: 200 },
    { name: 'Fruet Panch',price: 200 },
    { name: 'Strawberry Juice',price: 250 },
  ],
  Cake: [
    { name: 'White Forest',price: 100 },
    { name: 'Black Forest',price: 100 },
    { name: 'Pound Cake',price: 100 },
    { name: 'Fruit Cake',price: 100 },
    { name: 'Birthday Cake',price: 100 },
    { name: 'Cream Cake',price: 100 },
    { name: 'Sprice Cake',price: 100 },
    { name: 'Cake Pop',price: 100 },
  ],
  "Hot Drinks": [
    { name: 'Macchiato',price: 70 },
    { name: 'Tea',price: 50 },
    { name: 'Black Tea',price: 50 },
    { name: 'Cappuccino',price: 100 },
    { name: 'Hot Milk',price: 70 },
    { name: 'Coffee',price: 60 },
    { name: 'Black Coffee',price: 60 },
    { name: 'Black Tea With Lemon',price: 60 },
    { name: 'Hot Water With Lemon',price: 50 },
    { name: 'Traditional Coffee',price: 60 },
    { name: 'Café Latte',price: 90 },
    { name: 'Cafe Americano',price: 60 },
  ],
};
// const categoryImages = {
//   BreakFast: breakfastImage,
//   "Lunch and Dinner": lunchImage,
//   Burger: burgerImage,
//   Sandwich: sandwichImage,
//   "Ethiopian Food": ethiopianImage,
//   Pizza: pizzaImage,
//   Juice: juiceImage,
//   Cake: cakeImage,
//   "Hot Drinks": hotDrinksImage,
// };

function Menu() {
  const [selectedMenu, setSelectedMenu] = useState('BreakFast');
  const [categoryIndex, setCategoryIndex] = useState(0);
  // const [selectedImage, setSelectedImage] = useState(breakfastImage); // Initial image

  const categories = Object.keys(menuData);

  const startIndex = categoryIndex * 3;
  const endIndex = Math.min(startIndex + 3, categories.length);

  const handleNext = () => {
    if (categoryIndex < Math.ceil(categories.length / 3) - 1) {
      setCategoryIndex(categoryIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (categoryIndex > 0) {
      setCategoryIndex(categoryIndex - 1);
    }
  };


  const handleCategoryChange = (menu) => {
    setSelectedMenu(menu);
    // setSelectedImage(categoryImages[menu]);
  };

  return (
  <div className="bg-white text-black min-h-screen">
    <div className="container mx-auto py-10 px-4">
      <h1 className="text-3xl md:text-5xl text-center text-custom-blue font-serif mb-10">Restaurant Menu</h1>
      <div className="relative flex items-center justify-center mb-10">
        <div
          className={`absolute left-0 flex items-center justify-center bg-custom-blue text-white w-8 h-8 md:w-10 md:h-10 rounded-full cursor-pointer ${
            categoryIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handlePrevious}
        >
          <FaChevronLeft className="text-xl md:text-2xl" />
        </div>
        <div className="flex overflow-x-auto mx-4 md:mx-10 space-x-2 md:space-x-6 px-2 md:px-4">
          {/* Menu categories */}
          {categories.slice(startIndex, endIndex).map((menu) => (
            <div
              key={menu}
              className={`px-2 py-1 md:px-6 md:py-2 cursor-pointer text-sm md:text-base mx-1 md:mx-2 ${
                selectedMenu === menu
                  ? 'bg-white text-custom-blue font-serif border-custom-blue border-2 border-solid shadow-lg'
                  : 'bg-custom-blue text-white font-serif border-transparent'
              }`}
              style={{ minWidth: '75px' }}
              onClick={() => handleCategoryChange(menu)}
            >
              {menu}
            </div>
          ))}
        </div>
        <div
          className={`absolute right-0 flex items-center justify-center bg-custom-blue text-white w-8 h-8 md:w-10 md:h-10 rounded-full cursor-pointer ${
            categoryIndex === Math.ceil(categories.length / 3) - 1 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          onClick={handleNext}
        >
          <FaChevronRight className="text-xl md:text-2xl" />
        </div>
      </div>
 
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* <div className="col-span-full">
          <img src={selectedImage} alt={selectedMenu} className="w-full h-48 md:h-36 md:max-w-md rounded-lg shadow-md mb-6 mx-auto" />
        </div> */}
      
        <div className="col-span-full md:col-span-2 lg:col-span-3 overflow-x-auto overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
          {menuData[selectedMenu] ? (
            menuData[selectedMenu].map((item, index) => (
              <div key={index} className="rounded-lg bg-white p-6 flex flex-col justify-between shadow-md hover:shadow-lg transition-shadow duration-300 mb-4">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg md:text-xl lg:text-2xl  mb-2 text-custom-blue font-serif">{item.name}</h2>
                  <p className="text-custom-blue   font-serif">{item.price} Birr</p>
                </div>
                <div className="border-t border-dotted border-custom-blue my-2"></div>
                
              </div>
            ))
          ) : (
            <p className="text-black text-center">No items found for this menu.</p>
          )}
        </div>
      </div>
    </div>
  </div>
);

  
  
  
}

export default Menu;